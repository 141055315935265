import { format, parseISO, differenceInDays } from 'date-fns';
import { getLanguageLocale } from './localeRegistry';

const formatBuilder = async (formatStyle, localeStr) => {
  const { default: locale } = await getLanguageLocale(localeStr)();
  return (date) => format(parseISO(`${date}`), formatStyle, { locale });
};

export const getDifferenceInDays = (date1, date2 = date1) =>
  /* eslint-disable-next-line */
  differenceInDays(parseISO(date1), parseISO(date2));

// The entered query param has the format of yyyy-mm-dd:hh:mm
export const formatQueryToDate = (date) => format(parseISO(new Date(date).toISOString()), 'yyyy-MM-dd');
export const formatQueryToTime = (date) => format(parseISO(new Date(date).toISOString()), 'HH:mm');
export const formatQueryToDateTime = (date) => format(parseISO(new Date(date).toISOString()), 'yyyy-MM-dd HH:mm');

export const isoToShortDate = (locale) => formatBuilder('P', locale);
export const isoToDate = (locale) => formatBuilder('Pp', locale);
export const isoToDateTimeSeconds = (locale) => {
  const formatStyle = locale === 'en' ? 'yyyy-MM-dd HH:mm:ss' : 'd.M.yyyy HH:mm:ss';
  return formatBuilder(formatStyle, locale);
};
export const isoToDateTime = (locale) => {
  const formatStyle = locale === 'en' ? 'yyyy-MM-dd HH:mm' : 'd.M.yyyy HH:mm';
  return formatBuilder(`${formatStyle}`, locale);
};
export const isoToTimeSeconds = (locale) => formatBuilder('HH:mm:ss', locale);
