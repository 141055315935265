import { upperFirst, merge, clone, isNil, reduce } from 'lodash';

export default {
  noJackpotProducts: (state) => state.noJackpotProducts,
  activeFilters: (state, { platformName, currentPage }) => state.activeFilters[currentPage][platformName],
  allFilters: (state, getters) => merge(clone(getters.selectedFiltersPerTenant), getters.dateRange),
  allFiltersPerTenant: (state) => state.allFiltersPerTenant,
  auth: (state) => state.auth,
  collectedFilters: (state, { selectedFiltersPerTenant, dateRange }) => merge(clone(selectedFiltersPerTenant), dateRange),
  config: (state) => state.config,
  currentPage: (state) => state.currentPage,
  currentPageHeaders: (state, { currentPage }) => state.headers[currentPage],
  dateRange: (state) => state.dateRange,
  initialQueryParams: (state) => state.initialQueryParams,
  queryWithDate: (state) => state.queryWithDate,
  isInitialQueryParamsEmpty: ({ initialQueryParams }) => !reduce(
    initialQueryParams,
    (result, value) => {
      const isParamEmpty = !!value.length;
      return result || isParamEmpty;
    },
    false,
  ),
  nonEmptyInitialQueryParams: ({ initialQueryParams }) => reduce(
    initialQueryParams,
    (result, value, key) => {
      // eslint-disable-next-line
      if (value.length) result[key] = value;

      return result;
    },
    {},
  ),
  detailsModalConfig: (state) => state.detailsModalConfig,
  detailsModalToggler: (state) => state.toggleDetailsModal,
  downloadFile: (state) => state.downloadFile,
  footerProps: (state, getters) => ({
    'items-per-page-options': state.rowsCount,
    'items-per-page-text': getters.getTranslation('rowsPerPage'),
  }),
  getAllForFilterPerTenant: (state) => (filter) => state.allFiltersPerTenant[filter],
  getSelectedFilterPerTenant: (state, getters) => (filter) => getters.selectedFiltersPerTenant[filter],
  getTranslation: (state, getters) => (key) => getters.translations[key] || key,

  /**
   *  True means that the filters network rq failed.
   *  Even when the req fails, we still assign search to ''
   * @returns {boolean}
   */
  hasFiltersReqFailed: (state, getters) => Object.keys(getters.allFiltersPerTenant).length === 1,
  info: (state) => state.info,
  isDarkMode: (state) => state.theme === 'dark',
  isInfoVisible: (state) => !isNil(state.info),
  isMobile: (state) => state.isMobile,
  isMobileAndStandalone: (state, { isStandalone, isMobile }) => isStandalone && isMobile,
  isSeven: (state, getters) => getters.platformName === 'seven',
  isStandalone: (state, getters) => getters.platformName === 'standalone',
  keycloak: (state) => state.keycloak,
  layout: (state, getters) => upperFirst(getters.platformName),
  loading: (state) => state.loading,
  locale: (state) => state.config.locale,
  navigation: (state) => state.navigation,
  platformName: (state) => state.config.platformName,
  productInstanceUuid: (state, getters) => getters.config.productInstanceUuid,
  productName: (state, getters) => getters.config.productName,
  localStoragePath: (state, getters) => {
    let path = '';

    if (getters.isSeven) path = `${getters.currentPage}-${getters.config.productName}`;
    else {
      path = getters.currentPage === 'tickets'
        ? 'tickets'
        : `${getters.currentPage}-${getters.config.productName}`;
    }

    return path;
  },
  selectedFilters: (state) => state.selectedFilters,
  selectedFiltersPerTenant: (state) => state.selectedFiltersPerTenant,
  selectedTenants: (state) => state.selectedFiltersPerTenant.tenants,
  selectedTicket: (state) => state.selectedTicket,
  sortableFields: (state, { currentPage }) => state.sortableFields[currentPage],
  tenantId: (state, getters) => getters.config.tenantId,
  translations: (state) => state.translations,
};
