<template>
  <div class="pt-4">
    <v-divider></v-divider>
    <div v-for="(info, index) in gameSpecificGeneralInfo" :key="index" class="pl-3 mt-2">
      <v-row>
        <p class="subtitle-1 pt-6 mb-0 subtitle--text">{{ info.title }}</p>
      </v-row>
      <v-row class="table-wrapper">
        <v-col>
          <v-data-table
            class="betTable"
            disable-pagination
            :hide-default-footer="true"
            :headers="gameSpecificHeaders"
            :items="formattedBets"
          >
            <!-- IS PROMO RAIN AS CHIP  -->
            <template v-slot:[`item.isPromoRain`]="{ item }">
              <v-chip
                class="isPromoRain"
                :color="getPromoRainColor(item.isPromoRain)"
                small
                outlined
                label
                >{{ getTranslation(item.isPromoRain) }}</v-chip
              >
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-icon :color="item.statusColor">{{ item.status }}</v-icon>
            </template>
            <template v-slot:[`item.eventId`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn class="roboto-mono" color="primary" v-on="on" @click="onJumpToEvent(item.selection)">{{
                    getFormattedEventId(item.eventId)
                  }}</v-btn>
                </template>
                <span>{{ item.eventId }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <p class="subtitle-1 pt-6 mb-0 subtitle--text">{{ getTranslation('totals') }}</p>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
          lg="4"
          v-for="(item, index) in info.data"
          :key="index"
          class="ma-0 pa-0"
        >
          <div class="ticketDataWrapper">
            <p class="label body-2 mt-2">
              {{ item.label }}
            </p>
            <p :class="['value', 'caption', { 'subtitle--text': isDarkMode }]">
              {{ getFormattedInfo(selectedTicket, item.value) }}
            </p>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { get, remove, first } from 'lodash';
import { getStatusIcon, getStatusColor, roundDecimals, nilSentry } from '@/utility';
import { productTypes } from '@/utility/types';

export default {
  name: 'TicketDetails',
  data() {
    const { translations } = this.$store.getters;

    return {
      generalInfo: [
        {
          title: '',
          value: 'info',
          data: [
            {
              label: translations.stake,
              value: 'totals.stake.real.value',
            },
            {
              label: translations.payout,
              value: 'totals.payout.real.value',
            },
            {
              label: translations.status,
              value: 'status',
            },
            {
              label: translations.jackpot,
              value: 'totals.jackpot.real.value',
            },
            {
              label: translations.paymentTax,
              value: 'paymentTax',
            },
            {
              label: translations.payoutTax,
              value: 'payoutTax',
            },
          ],
        },
      ],
      betHeaders: [
        {
          text: translations.round,
          align: 'start',
          sortable: false,
          value: 'round',
        },
        {
          text: translations.eventId,
          align: 'start',
          sortable: false,
          value: 'eventId',
        },
        {
          text: translations.type,
          align: 'start',
          sortable: false,
          value: 'type',
        },
        {
          text: translations.pick,
          align: 'start',
          sortable: false,
          value: 'pick',
        },
        {
          text: translations.luckyMultiplier,
          align: 'start',
          sortable: false,
          value: 'luckyMultiplier',
        },
        {
          text: translations.isPromoRain,
          align: 'start',
          sortable: false,
          value: 'isPromoRain',
        },
        {
          text: translations.odds,
          align: 'start',
          sortable: false,
          value: 'odds',
        },
        {
          text: translations.stake,
          align: 'end',
          sortable: false,
          value: 'stake',
        },
        {
          text: translations.payoutTax,
          align: 'end',
          sortable: false,
          value: 'payoutTax',
        },
        {
          text: translations.paymentTax,
          align: 'end',
          sortable: false,
          value: 'paymentTax',
        },
        {
          text: translations.winnings,
          align: 'end',
          sortable: false,
          value: 'winnings',
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'status',
        },
      ],
    };
  },
  methods: {
    ...mapActions([
      'setCurrentPage',
      'setInitialQueryParams',
      'setQueryWithDate',
      'toggleDetailsModal',
    ]),
    onJumpToEvent({ eventId, productInstanceId }) {
      this.setQueryWithDate(false);
      this.toggleDetailsModal(false);
      this.$router.push('/events');
      this.setCurrentPage('events');
      this.setInitialQueryParams({ search: eventId, instances: [productInstanceId] });
    },
    getPromoRainColor(value) {
      // We already have colors for won and lost
      // We're just reusing them here
      return value ? 'won' : 'lost';
    },
    localiseAndRoundDecimals(number) {
      return roundDecimals(number, this.locale);
    },
    getVDRBetType(marketId) {
      const raceWinMarketId = 1;
      return this.getTranslation(marketId === raceWinMarketId ? 'raceWin' : 'outrights');
    },
    getProperType(type, marketId) {
      if (this.isProductVDR) {
        if (!marketId) throw new Error('marketId is undefined');

        return this.getVDRBetType(marketId);
      }

      return this.getTranslation(type);
    },
    getIsBetWithPromoRain(bet) {
      return !!bet.winnings.bonuses.find(({ id }) => id === 'promoRain');
    },
    formatBet(bet) {
      // Each bet has a reference to a selection. The selection contains more info
      // on the corresponding bet, like the odds, the type, etc.
      const { selections } = this.selectedTicket;
      const { selectionIndex } = bet.selectionRefs[0];
      const selection = selections[selectionIndex];
      return {
        ...bet,
        round: selection.eventDisplayId,
        type: this.getProperType(selection.typeName || selection.marketName, selection.marketId),
        eventId: selection.eventId,
        pick: this.getProperPick(selection),
        result: this.localiseAndRoundDecimals(selection.result),
        odds: this.localiseAndRoundDecimals(selection.odds),
        stake: this.localiseAndRoundDecimals(bet.stake.real.value),
        winnings: this.localiseAndRoundDecimals(bet.winnings.total.value),
        selection,
        status: getStatusIcon(bet.status),
        statusColor: getStatusColor(bet.status),
        isPromoRain: this.getIsBetWithPromoRain(bet),
        selections,
        paymentTax:
          nilSentry(
            bet.taxes.find(({ type }) => type === 'PAYIN')?.value,
            this.localiseAndRoundDecimals,
          ) || '-',
        payoutTax:
          nilSentry(
            bet.taxes.find(({ type }) => type === 'PAYOUT')?.value,
            this.localiseAndRoundDecimals,
          ) || '-',
        // This is only temporary, in the future we will have a bets.winings.bonuses.luckyMultiplier for each bet
        luckyMultiplier: selection.additionalDetails.luckyMultiplier || '-',
      };
    },
    getFormattedEventId(eventId) {
      const firstBeforeDash = first(eventId.split('-'));
      return `${firstBeforeDash}...`;
    },
    getFormattedInfo(ticket, path) {
      return get(ticket, path) || '-';
    },
    getOutcomeName(outcomeName) {
      let name = null;
      const splitedName = outcomeName.split(':');
      name = this.getTranslation(`racer_${splitedName[0]}`);
      name = splitedName.length === 2
        ? `${splitedName[0]} - ${name} ${this.getTranslation(splitedName[1])}`
        : `${splitedName[0]} - ${name}`;
      return name;
    },
    getProperPick(selection) {
      const zeroStrings = ['0', '0.0', '0.00', '0,0', '0,00'];
      let pick = null;
      if (this.isProductCrashCash) {
        pick = this.localiseAndRoundDecimals(selection.outcomeId);
        pick = zeroStrings.includes(pick) ? '-' : pick;
      } else if (this.isProductVDR) {
        pick = this.getOutcomeName(selection.outcomeName);
      } else {
        pick = selection.outcomeName;
      }
      return pick;
    },
  },
  computed: {
    ...mapGetters([
      'selectedTicket',
      'translations',
      'getTranslation',
      'locale',
      'getTranslation',
      'noJackpotProducts',
      'productName',
      'product',
    ]),
    isProductVDR() {
      return [productTypes.VirtualDragRaces].includes(this.selectedTicket.productName);
    },
    isProductCrashCash() {
      return [productTypes.CrashCash, productTypes['cash-crash']].includes(
        this.selectedTicket.productName,
      );
    },
    isDarkMode() {
      return this.$vuetify.theme.dark;
    },
    formattedBets() {
      const { bets } = this.selectedTicket;
      return [...bets.map(this.formatBet)];
    },
    gameSpecificHeaders() {
      const headers = [...this.betHeaders];
      if (!this.isProductVDR) return headers;

      remove(headers, (el) => el.value === 'luckyMultiplier' || el.value === 'eventId');
      remove(headers, (el) => el.value === 'isPromoRain' || el.value === 'eventId');

      return headers;
    },
    isNoJackpotProduct() {
      return this.noJackpotProducts.includes(this.selectedTicket.productName);
    },
    gameSpecificGeneralInfo() {
      const generalInfo = [...this.generalInfo];
      if (this.isNoJackpotProduct) {
        generalInfo[0].data = generalInfo[0].data.filter(
          ({ label }) => label !== this.translations.jackpot,
        );
      }
      return generalInfo;
    },
  },
};
</script>

<style lang="scss" scoped>
.betTable {
  ::v-deep .v-data-table__wrapper {
    border-bottom: thin solid hsla(0, 0%, 100%, 0.12);
  }
}

.roboto-mono {
  font-family: "Roboto Mono", monospace;
  font-optical-sizing: auto;
}

.table-wrapper {
  max-height: 500px;
  overflow: auto;
}

.ticketDataWrapper {
  border: 1px solid rgba(128, 128, 128, 0.3);
  border-radius: 4px;
  margin: 12px 12px 0 0;

  .label,
  .value {
    margin: 3px 10px;
  }
}
</style>
