<template>
  <div><SevenTabs /> <router-view /></div>
</template>

<script>
import SevenTabs from '@/components/SevenTabs';
import { mapActions } from 'vuex';

export default {
  name: 'seven',
  components: {
    SevenTabs,
  },
  methods: {
    ...mapActions(['setCurrentPage']),
  },
  watch: {
    $route(to) {
      if (!to.name) return;

      this.setCurrentPage(to.name.toLowerCase());
    },
  },
};
</script>

<style lang="scss" scoped></style>
