<template>
  <div>
    <v-bottom-navigation background-color="background" fixed height="60">
      <v-btn
        v-for="item in navigation"
        :key="item.title"
        :to="item.link"
        text
        active-class="toActive"
      >
        <span class="mobileNavigationTitle">{{ translations[item.title] }}</span>
        <v-icon class="mb-1">{{ item.icon }}</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'navbarMobile',
  computed: {
    ...mapGetters(['navigation', 'translations']),
  },
};
</script>

<style lang="scss" scoped>
.mobileNavigationTitle {
  font-size: 11px !important;
  font-weight: 400 !important;
  letter-spacing: 0.4px;
}
.v-btn::before {
  background-color: transparent !important;
}
.toActive {
  color: var(--v-primary-base) !important;
}
</style>
