const light = {
  standalone: {
    accent: '#82B1FF',
    background: '#FFFFFF',
    chips: '#CDCDCD',
    disabled: '#8E8E8E',
    error: '#FF5252',
    info: '#2196F3',
    primary: '#EE44AA',
    secondary: '#424242',
    subtitle: '#BCBCBC',
    success: '#4CAF50',
    warning: '#FFC107',
    lost: '#CD6D6D',
    open: '#009AFF',
    won: '#80C45A',
    pending: '#808B9C',
    paidout: '#BBB04C',
    expired: '#808B9C',
    rejected: '#CD6D6D',
    closed: '#808B9C',
    accepted: '#009AFF',
    canceled: '#808B9C',
  },
  seven: {
    primary: '#009AFF',
    secondary: '#424242',
    accent: '#38ACF0',
    error: '#FF5252',
    info: '#2196F3',
    text: '#FFFFFF',
    subtitle: '#BCBCBC',
    success: '#4CAF50',
    warning: '#FB8C00',
    darkGrey: '#2E3743',
    lightGrey: '#D3DADD',
    disabled: '#8E8E8E',
    open: '#009AFF',
    lost: '#CD6D6D',
    accepted: '#009AFF',
    canceled: '#808B9C',
    won: '#80C45A',
    jackpot: '#80C45A',
    closed: '#808B9C',
    paidout: '#BBB04C',
    expired: '#808B9C',
    pending: '#808B9C',
    rejected: '#CD6D6D',
  },
};

export default light;
