<template>
  <div class="filterToggler">
    <v-dialog v-model="filterDialog" fullscreen transition="dialog-bottom-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" class="text-none" height="40" block>
          <LocalizedLabel>filters</LocalizedLabel>
          <v-icon right> mdi-chevron-down </v-icon>
        </v-btn>
      </template>
      <v-card v-touch="{ down: () => swipe() }">
        <v-toolbar>
          <v-btn icon @click="filterDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <LocalizedLabel>filters</LocalizedLabel>
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <div class="px-4 pt-8">
          <FilterHandler
            ref="filterHandler"
            :payload="payload"
            :inDialog="true"
            @setFilters="setFilters"
          />
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import FilterHandler from '@/components/Filters/FilterHandler';

export default {
  name: 'filterDialog',
  props: {
    payload: {
      type: Object,
    },
  },
  components: {
    FilterHandler,
  },
  data() {
    return {
      filterDialog: false,
    };
  },
  async mounted() {
    await this.initFiltersWrapper();
    this.$emit('filtersReady');
  },
  methods: {
    ...mapActions(['initFilters']),
    getAndStoreInitialFilters() {
      this.$refs.filterHandler.getAndStoreInitialFilters();
    },
    setFilters(name, value) {
      this.$emit('setFilters', name, value);
    },
    swipe() {
      this.filterDialog = false;
    },
    async initFiltersWrapper() {
      const route = this.$route;
      const { dateFrom, dateTo } = this.$route;
      await this.initFilters({ route, dateFrom, dateTo });
    },
  },
};
</script>
<style lang="scss" scoped>
.filterToggler > .v-btn {
  justify-content: space-between;
  margin: 16px 0;
  border: 1px solid #ffffff81;
  background-color: transparent !important;
  font-weight: 300;
  opacity: 0.7;
  .v-icon {
    opacity: 0.7;
  }
}
</style>
