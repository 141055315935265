<template>
  <div>
    <v-navigation-drawer
      class="navigationWrapper"
      color="background"
      permanent
      app
      width="100"
    >
      <v-list>
        <v-list-item to="/">
          <v-row class="my-2">
            <v-list-item-avatar height="48" width="48" class="mx-auto">
              <v-img src="./../../public/assets/logo.png"></v-img>
            </v-list-item-avatar>
          </v-row>
        </v-list-item>
        <v-list-item
          v-for="item in navigation"
          :key="item.title"
          link
          two-line
          @click="getNavigationPath(item.link)"
          :class="getNavigationStyle(item.link)"
        >
          <v-list-item-content class="text-center">
            <v-icon class="mb-1" color="disabled">{{ item.icon }}</v-icon>
            <span class="navigationTitle disabled--text">{{ getTranslation(item.title) }}</span>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <SettingsMenu />
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import SettingsMenu from '@/components/SettingsMenu';

export default {
  name: 'navbarDesktop',
  methods: {
    ...mapActions(['setCurrentPage']),
    getNavigationPath(value) {
      if (this.$route.path !== value) {
        this.$router.push(value);
      }
      this.setCurrentPage(value.replace('/', ''));
    },
    getNavigationStyle(value) {
      return this.$route.matched[0].path === value ? 'activeLink' : '';
    },
  },
  computed: {
    ...mapGetters(['navigation', 'getTranslation']),
  },
  components: {
    SettingsMenu,
  },
};
</script>

<style lang="scss" scoped>
.navigationWrapper {
  .navigationTitle {
    font-size: 12px !important;
    font-weight: 400 !important;
    letter-spacing: 0.4px;
  }

  .activeLink {
    background-color: #545454 !important;

    .navigationTitle,
    i {
      color: var(--v-text-base) !important;
    }
  }

  .theme--light.activeLink {
    background-color: #e5e5e5 !important;
  }
}
</style>
