export default {
  CrashCash: 'CrashCash',
  VirtualDragRaces: 'VirtualDragRaces',
  GamesTest: 'GamesTest',
  NVNVKolor: 'NVNVKolor',
  NVNVLightningRoulette: 'NVNVLightningRoulette',
  NumbersBetting: 'NumbersBetting',
  Roulette: 'Roulette',
  SpinAndWin: 'SpinAndWin',
  'cash-crash': 'cash-crash',
};
