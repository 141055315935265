const constructHeader = (key) => ({
  text: key,
  align: 'start',
  sortable: false,
  value: key,
  default: true,
  visible: true,
});

export default constructHeader;
