<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        prepend-inner-icon="mdi-calendar"
        readonly
        dense
        outlined
        offset-y
        :label="label"
        v-bind="attrs"
        hide-details
        v-on="on"
        v-model="dateText"
        @click:append="on"
      ></v-text-field>
    </template>
    <v-date-picker
      no-title
      range
      v-model="date"
      scrollable
      :max="maxDate"
      @input="onDateInput"
      first-day-of-week="1"
      class="dateTimePicker"
      color="primary"
    >
      <div class="dateTimeMenuFooterWrapper">
        <v-row>
          <v-col>
            <v-menu v-model="startTimeMenu" ref="startTimeMenuRef" :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startTime"
                  prepend-icon="mdi-clock-outline"
                  background-color="background lighten-1"
                  hide-details
                  dense
                  readonly
                  single-line
                  solo
                  flat
                  v-bind="attrs"
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-time-picker
                @click:minute="$refs.startTimeMenuRef.save(startTime)"
                v-if="startTimeMenu"
                v-model="startTime"
                format="24hr"
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-menu v-model="endTimeMenu" ref="endTimeMenuRef" :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endTime"
                  background-color="background lighten-1"
                  prepend-icon="mdi-clock-outline"
                  hide-details
                  dense
                  readonly
                  single-line
                  solo
                  flat
                  v-bind="attrs"
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-time-picker
                @click:minute="$refs.endTimeMenuRef.save(endTime)"
                v-if="endTimeMenu"
                v-model="endTime"
                format="24hr"
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row justify="end">
          <div class="pa-4 pb-2">
            <v-btn text @click="resetDate">
              <LocalizedLabel>reset</LocalizedLabel>
            </v-btn>
            <v-btn
              text
              color="primary"
              :disabled="!isDateTimeInputValid"
              @click="setDateRange(date)"
            >
              <LocalizedLabel>accept</LocalizedLabel>
            </v-btn>
          </div>
          <v-col cols="12" sm="12" class="py-0 px-4">
            <v-alert
              type="error"
              dense
              v-if="isNotificationVisible"
              outlined
              color="error"
              text
              class="caption"
            >
              {{ notification }}
            </v-alert>
          </v-col>
        </v-row>
      </div>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { isBefore, formatISO, parseISO, format } from 'date-fns';
import { template, set } from 'lodash';
import {
  getDifferenceInDays,
  formatQueryToDate,
  formatQueryToTime,
  formatQueryToDateTime,
  isRangeEntireDay,
  nilSentry,
} from '@/utility';

export default {
  props: {
    label: { type: String },
    dateFromSelected: { type: String },
    dateToSelected: { type: String },
    maxRange: { type: Number, default: 0 },
  },
  data() {
    return {
      endTimeMenu: false,
      startTimeMenu: false,
      startTime: '00:00',
      endTime: '23:59',
      menu: false,
      differenceInDays: 0,
      maxDate: new Date().toISOString().substr(0, 10),
      date: [],
    };
  },
  methods: {
    ...mapActions({ setDateRangeStore: 'setDateRange' }),
    onDateInput() {
      if (this.maxRange === 0) {
        this.date = [...this.date, ...this.date];
      }
      this.differenceInDays = Math.abs(getDifferenceInDays(this.date[0], this.date[1]));
    },
    cancelPicker() {
      this.menu = false;
    },
    setMenu(value) {
      this.menu = value;
    },
    resetDate() {
      const now = new Date();
      const today = format(now, 'yyyy-MM-dd');
      if (this.date[0] === today && this.startTime === '00:00' && this.endTime === '23:59') return;
      set(this, 'date', [today, today]);
      set(this, 'startTime', '00:00');
      set(this, 'endTime', '23:59');
      this.setDateRange(this.date);
    },
    getRangeWithCorrectTime(date) {
      // The left time-picker should always attach to the start of the date range
      const indexOfEarlierDay = isBefore(new Date(date[0]), new Date(date[1])) ? 0 : 1;
      const indexOfLaterDay = indexOfEarlierDay === 0 ? 1 : 0;
      const dateFrom = formatISO(new Date(`${date[indexOfEarlierDay]}T${this.startTime}`));
      const dateTo = formatISO(new Date(`${date[indexOfLaterDay]}T${this.endTime}:59`));

      return { dateFrom, dateTo };
    },

    setDateRange(date) {
      // Allow the user to click once on a day to select it
      if (date.length === 1) {
        date.push(...date);
      }

      const filters = this.getRangeWithCorrectTime(date);
      this.setDateRangeStore(filters);
      this.$emit('updateDateRange', date);
    },
  },
  computed: {
    ...mapGetters(['translations']),
    dateText() {
      if (this.isEntireDaySelected) {
        return nilSentry(this.dateFromSelected, formatQueryToDate);
      }

      let text = '';
      const dateFrom = nilSentry(this.dateFromSelected, formatQueryToDateTime);

      // Refrain from trying to refactor this with a ternary operator.
      // fromatQueryToDateTime doesn't take undefined.
      if (this.dateToSelected) text = `${dateFrom} ~ ${formatQueryToDateTime(this.dateToSelected)}`;
      else {
        text = dateFrom;
      }

      return text;
    },
    isEntireDaySelected() {
      return this.dateFromSelected && this.dateToSelected
        ? isRangeEntireDay(parseISO(this.dateFromSelected), parseISO(this.dateToSelected))
        : false;
    },
    isNotificationVisible() {
      return !this.isDateTimeInputValid && this.date.length !== 0;
    },
    isDateTimeInputValid() {
      return this.isDateValid && this.isTimeValid;
    },
    /**
     * The date selection is considered valid if
     * it respects the maximum range defined,
     * and if it select at least one date.
     */
    isDateValid() {
      return this.differenceInDays <= this.maxRange && this.date.length !== 0;
    },
    /**
     * Checks if the time selection makes sense.
     */
    isTimeValid() {
      const isMoreThanOneDayOrUnselected = this.differenceInDays > 0;
      const isStartTimeBeforeEndTime = isBefore(
        new Date(`2000-01-01T${this.startTime}`),
        new Date(`2000-01-01T${this.endTime}`),
      );

      return isMoreThanOneDayOrUnselected || isStartTimeBeforeEndTime;
    },
    notification() {
      const rangeErrorNotification = template(this.translations.selectDateRangeErrorLabel)({
        value: this.maxRange + 1,
      });
      const timeErrorNotification = this.translations.selectTimeRangeErrorLabel;

      return this.isDateValid ? timeErrorNotification : rangeErrorNotification;
    },
  },
  created() {
    // The format for the url bar param is ISO
    // Converting it here to a format the date time picker understands
    if (this.dateFromSelected) {
      this.date[0] = formatQueryToDate(this.dateFromSelected);
      this.startTime = formatQueryToTime(this.dateFromSelected);
    }
    if (this.dateToSelected) {
      this.date[1] = formatQueryToDate(this.dateToSelected);
      this.endTime = formatQueryToTime(this.dateToSelected);
    }
  },
  watch: {
    dateFromSelected() {
      if (!this.dateFromSelected) return;

      this.date[0] = formatQueryToDate(this.dateFromSelected);
      this.date = [...this.date];
      this.startTime = formatQueryToTime(this.dateFromSelected);
    },
    dateToSelected() {
      if (!this.dateToSelected) return;

      this.date[1] = formatQueryToDate(this.dateToSelected);
      this.date = [...this.date];
      this.endTime = formatQueryToTime(this.dateToSelected);
    },
  },
};
</script>

<style lang="scss" scoped>
.disabledSelect {
  color: var(--v-subtitle-base) !important;
}
</style>
