<template>
  <div :class="{ 'pa-4': isSeven }">
    <TableFilterGrid v-bind="productProps" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import TableFilterGrid from '@/components/TableFilterGrid';
import listTypes from '@/utility/types/listTypes';
import {
  nilSentry,
  isoToDateTimeSeconds,
  isoToTimeSeconds,
  roundDecimals,
  productRegistry,
  constructHeader,
} from '@/utility';
import { partial, isNil } from 'lodash';

export default {
  name: 'CustomEventGrid',
  components: {
    TableFilterGrid,
  },
  computed: {
    ...mapGetters([
      'allFiltersPerTenant',
      'currentPageHeaders',
      'selectedFiltersPerTenant',
      'getTranslation',
      'isSeven',
      'locale',
      'productInstanceUuid',
      'productName',
      'sortableFields',
    ]),
    headersWithSortableFields() {
      return this.currentPageHeaders.map((header) => ({
        ...header,
        sortable: this.sortableFields.includes(header.value),
      }));
    },
    product() {
      return productRegistry.getProduct(this.productName) || {};
    },
    vdrProps() {
      return {
        customUrl: `${process.env.VUE_APP_VDR_ENDPOINT}/${this.productInstanceUuid}/${listTypes.EVENTS}`,
        listName: listTypes.EVENTS,
        transformList: this.transformList(this.formatVDREventForView),
        transformTotal: this.transformTotal,
        onClickRow: () => {},
        inferTableHeaders: true,
        headers: this.headersWithSortableFields,
      };
    },
    ccProps() {
      return {
        listName: listTypes.EVENTS,
        transformList: this.transformList(this.formatCCEventForView, this.allFiltersPerTenant),
        transformTotal: this.transformTotal,
        onClickRow: () => {},
        inferTableHeaders: true,
        headers: this.headersWithSortableFields,
        additionalHeaders: [constructHeader('gameName')],
      };
    },
    productProps() {
      let props;
      switch (this.product.name) {
        case 'crashcash':
          props = this.ccProps;
          break;
        case 'vdr':
          props = this.vdrProps;
          break;
        default:
          break;
      }
      return props;
    },
  },
  methods: {
    ...mapActions(['getData']),
    getRacerKey(id) {
      return `racer_${id}`;
    },
    formatVDREventForView({ formatters }, event) {
      const { dateFormatter } = formatters;

      return {
        ...event,
        startedAt: nilSentry(event.startedAt, dateFormatter),
        contestantOneId: `${event.contestantOneId} - ${this.getTranslation(
          this.getRacerKey(event.contestantOneId),
        )}`,
        contestantTwoId: `${event.contestantTwoId} - ${this.getTranslation(
          this.getRacerKey(event.contestantTwoId),
        )}`,
        tournamentPhase: this.getTranslation(event.tournamentPhase),
        winner: isNil(event.winner)
          ? '-'
          : `${event.winner} - ${this.getTranslation(this.getRacerKey(event.winner))}`,
        contestantOneOdds: nilSentry(event.contestantOneOdds, this.localiseAndRoundDecimals),
        contestantTwoOdds: nilSentry(event.contestantTwoOdds, this.localiseAndRoundDecimals),
      };
    },
    formatCCEventForView({ formatters, additionalData }, event) {
      const { dateFormatter, timeFormatter } = formatters;
      return {
        ...event,
        multiplierStartedAt: nilSentry(event.multiplierStartedAt, timeFormatter),
        gameName: additionalData.instances?.find(({ value }) => value === event.gameId).name,
        createdAt: nilSentry(event.createdAt, dateFormatter),
        startedAt: nilSentry(event.startedAt, dateFormatter),
        endedAt: nilSentry(event.endedAt, dateFormatter),
        luckyMultiplier:
          event.luckyMultiplier !== '0'
            ? nilSentry(event.luckyMultiplier, this.localiseAndRoundDecimals)
            : '-',
        multiplier: nilSentry(event.multiplier, this.localiseAndRoundDecimals),
      };
    },
    localiseAndRoundDecimals(num) {
      return roundDecimals(num, this.locale);
    },
    transformTotal(value) {
      return value;
    },
    transformList(callback, additionalData = {}) {
      return async function transformer(data) {
        const dateFormatter = await isoToDateTimeSeconds(this.locale);
        const timeFormatter = await isoToTimeSeconds(this.locale);

        const formatters = {
          dateFormatter,
          timeFormatter,
        };

        const events = data?.map(partial(callback, { formatters, additionalData }));

        return events;
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
