<template>
  <div class="tabs">
    <v-tabs
      dark
      background-color="darkGrey"
      slider-color="primary"
      height="43"
      v-model="currentTab"
    >
      <v-tab
        v-for="item in navigation"
        :key="item.title"
        :title="item.title"
        @change="getNavigationPathAndSetCurrentPage(item.link)"
        @click="getNavigationPathAndSetCurrentPage(item.link)"
      >
        {{ getTranslation(item.title) }}
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { sevenTabTypes } from '@/utility/types';

export default {
  name: 'sevenTabs',
  data() {
    const { navigation } = this.$store.getters;

    return {
      currentTab: navigation.findIndex(({ type }) => type === sevenTabTypes.tickets),
    };
  },
  methods: {
    ...mapActions([
      'setCurrentPage',
      'setInfo',
      'setSelectedFiltersPerTenantValues',
      'clearInitialQueryParams',
    ]),
    setTabValueByTabType(tabType) {
      this.currentTab = this.findTabByType(sevenTabTypes[tabType]);
    },
    findTabByType(tabType) {
      return this.navigation.findIndex(({ type }) => type === tabType);
    },
    getNavigationPathAndSetCurrentPage(value) {
      this.setInfo(null);
      this.setTabValueByTabType(value);

      if (this.$route.path !== value) {
        this.clearInitialQueryParams();
        this.setSelectedFiltersPerTenantValues({ search: '' });
        this.$router.push(value);
      }
      this.setCurrentPage(value.replace('/', ''));
    },
  },
  computed: {
    ...mapGetters(['navigation', 'getTranslation', 'currentPage']),
  },
  watch: {
    currentPage(newValue) {
      this.setTabValueByTabType(newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  ::v-deep .v-tabs-slider-wrapper {
    top: 0;
  }

  ::v-deep .v-tabs-slider {
    height: 3px;
  }
}
</style>
