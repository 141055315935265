export const selectedColumnsFilter = (headers, lsHeaders) => {
  const selectedColumns = headers.filter((column) => {
    const lsHeader = lsHeaders.find(({ value }) => column.value === value);
    return lsHeader ? lsHeader.visible : false;
  });
  return selectedColumns;
};

export const getColumnsData = (path) => {
  let data;
  if (localStorage.getItem(path)) data = JSON.parse(localStorage.getItem(path));
  return data;
};

/**
 * Stringifies received data and sets it as the value for the received path
 * @param {*} path
 * @param {*} data
 */
export const setLSData = (path, data) => {
  localStorage.setItem(path, JSON.stringify(data));
};
