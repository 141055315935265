import { forEach, cloneDeep, defaultTo, head } from 'lodash';

// The motivation behind this mapper is the fact
// that some filters are of type string[], and some of
// type {id: string, name: string}[]
// Note that dateFrom and dateTo are also filters, but are handled
// elsewhere
const filtersMapper = [
  { filterName: 'players', propToFilterBy: 'value' },
  { filterName: 'betshops', propToFilterBy: 'value' },
  { filterName: 'operators', propToFilterBy: 'value' },
  { filterName: 'tenants', propToFilterBy: 'value' },
  { filterName: 'instances', propToFilterBy: 'value' },
  { filterName: 'products', propToFilterBy: '' },
  { filterName: 'channels', propToFilterBy: '' },
  { filterName: 'statuses', propToFilterBy: '' },
  { filterName: 'search', propToFilterBy: '' },
];

const formatQueryParams = (value = {}) => {
  const queryWithDate = defaultTo(value.queryWithDate, true);
  const queryList = [];

  if (value) {
    if (value.offset && value.limit) {
      queryList.push(`offset=${value.offset}`);
      queryList.push(`limit=${value.limit}`);
    } else if (value.page && value.itemsPerPage) {
      queryList.push(`page=${value.page}`);
      queryList.push(`size=${value.itemsPerPage}`);
    }

    if (head(value.sortBy)) {
      // the opposite of what the sortDesc[0] says because we first want desc and then asc
      // this also works visually because of a custom icon for sort (check src/plugins/vuetify.js)
      queryList.push(`sort=${value.sortDesc[0] ? 'asc' : 'desc'}|${value.sortBy}`);
    }

    if (value.locale) {
      queryList.push(`locale=${encodeURIComponent(value.locale)}`);
    }

    if (value.dateFrom && queryWithDate) {
      queryList.push(`dateFrom=${encodeURIComponent(new Date(value.dateFrom).toISOString())}`);
    }

    if (value.dateTo && queryWithDate) {
      const dateTo = new Date(value.dateTo);
      dateTo.setMilliseconds(999);
      queryList.push(`dateTo=${encodeURIComponent(dateTo.toISOString())}`);
    }

    if (value.timezone) {
      queryList.push(`timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`);
    }

    filtersMapper.forEach(({ filterName, propToFilterBy }) => {
      if (value[filterName] && value[filterName]?.length) {
        // When a single value is selected, this.$route.query[filterName] return a string,
        // otherwise an array
        const filterValueString = Array.isArray(value[filterName])
          ? value[filterName]
          // This covers the when the filter is of primitive type (string)
            .map((filter) => defaultTo(filter[propToFilterBy], filter))
            .join(',')
          : value[filterName];

        queryList.push(`${filterName}=${encodeURIComponent(filterValueString)}`);
      }
    });
  }
  return queryList.join('&');
};

const getFilterMapperByName = (name) => {
  const [filterMapperObj] = filtersMapper.filter(({ filterName }) => filterName === name);

  return filterMapperObj;
};

const prepareForQueryParamsReplace = (filters) => {
  const newParams = cloneDeep(filters);

  forEach(filters, (filterValue, filterName) => {
    const mapperObj = getFilterMapperByName(filterName);

    if (mapperObj) {
      // Filters in our state are of type string || Array<string> | Array<any>
      // e.g. search: '', products: ['Keno'], tenants: [{value:tenantId, name: 'Prva Firma'}]
      if (filterValue) {
        if (Array.isArray(filterValue) && filterValue.length) {
          newParams[filterName] = filterValue.map((filter) => defaultTo(filter[mapperObj.propToFilterBy], filter));
        } else {
          // This covers the when the filter is of primitive type (string)
          newParams[filterName] = filterValue;
        }
      } else {
        delete newParams[filterName];
      }
    }
  });

  return newParams;
};
/**
 * This function does two things: filters out only the filters
 * that exist in the query params and in the state, and also transforms
 * the filter objects to fit the state model
 * @param {*} filter
 * @param {*} allFilters
 * @returns
 */
const transformParamObjsToStateObjs = ({ filterKey, filterValue }, allFilters) => {
  if (!allFilters[filterKey]) {
    console.warn(`All filters do not contain a filter of key ${filterKey}`);
    return '';
  }
  if (filterKey === 'search') return filterValue;

  let sievedFilters = [];
  // eslint-disable-next-line
  const filterValues = Array.isArray(filterValue) ? filterValue : [filterValue];
  // eslint-disable-next-line
  const selectedFilterMapper = getFilterMapperByName(filterKey);

  if (selectedFilterMapper) {
    sievedFilters = allFilters[filterKey].filter((localFilterObj) => {
      const valueToSearch = defaultTo(
        localFilterObj[selectedFilterMapper.propToFilterBy],
        localFilterObj,
      );

      return filterValues.includes(valueToSearch);
    });
  }
  return sievedFilters;
};

/**
 *
 * Takes the query object and creates an object that fits our state model
 * @param {query} query
 * @param {Array<Object | string>} allFilters
 */
const fromParamsObjToStateObj = (query, allFilters) => {
  const stateModel = {};

  const singleValueMapper = (value, key) => {
    stateModel[key] = value;
  };

  const multipleValueMapper = (value, key) => {
    const sievedFilters = transformParamObjsToStateObjs(
      { filterKey: key, filterValue: value },
      allFilters,
    );
    stateModel[key] = sievedFilters;
  };

  const filterHandlers = {
    search: singleValueMapper,
    sort: singleValueMapper,
  };

  forEach(filtersMapper, ({ filterName }) => {
    filterHandlers[filterName] = multipleValueMapper;
  });

  forEach(query, (value, key) => filterHandlers[key]?.(value, key));

  return stateModel;
};

export { prepareForQueryParamsReplace, formatQueryParams, fromParamsObjToStateObj };
