<template>
  <div :class="{ 'pa-4': isSeven }">
    <TableFilterGrid v-bind="tableAndFiltersProps" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import TableFilterGrid from '@/components/TableFilterGrid';
import listTypes from '@/utility/types/listTypes';
import {
  nilSentry,
  roundDecimals,
  getStatusTranslationKey,
  isoToTimeSeconds,
  isoToDateTimeSeconds,
  isoToDateTime,
} from '@/utility';
import { update, partial } from 'lodash';

export default {
  name: 'TicketsGrid',
  data() {
    return {
      href: '',
    };
  },
  computed: {
    ...mapGetters([
      'isSeven',
      'platformName',
      'getTranslation',
      'footerProps',
      'loading',
      'locale',
      'activeFilters',
      'sortableFields',
      'currentPageHeaders',
    ]),
    headersWithSortableFields() {
      return this.currentPageHeaders.map((header) => ({
        ...header,
        sortable: this.sortableFields.includes(header.value),
      }));
    },
    tableAndFiltersProps() {
      return {
        listName: listTypes.TICKETS,
        transformList: this.transformTickets,
        transformTotal: this.transformTotal,
        onClickOnRow: this.showTicketDetails,
        headers: this.headersWithSortableFields,
      };
    },
  },
  methods: {
    ...mapActions([
      'toggleDetailsModal',
      'setDetailsModalConfig',
      'setSelectedTicket',
      'getData',
      'setDownloadFile',
    ]),
    localiseAndRoundDecimals(number) {
      return roundDecimals(number, this.locale);
    },
    getPlayerEmail(ticket) {
      const { origin } = ticket;

      const playerEmail = origin.find(
        ({ source, type }) => source === 'PAYIN' && type === 'PLAYER',
      )?.name;

      return playerEmail || '-';
    },
    /**
     * Transforms the tickets array to a view-appropriate format
     */
    formatTicketForView(formatters, ticket) {
      const { dateSecondsFormatter } = formatters;
      const correctDateFormatter = dateSecondsFormatter;

      // For readability's sake
      update(ticket, 'totals.stake.real.value', this.localiseAndRoundDecimals);
      update(ticket, 'totals.payout.real.value', this.localiseAndRoundDecimals);
      if (ticket.totals.canceledAmount) {
        update(ticket, 'totals.canceledAmount.real.value', this.localiseAndRoundDecimals);
      } else {
        update(ticket, 'totals.canceledAmount', () => ({ real: { value: '-', type: 'TOTAL' } }));
      }

      return {
        ...ticket,
        id: ticket.id.split('-')[ticket.id.split('-').length - 1],
        status: this.getTranslation(getStatusTranslationKey(ticket.status)),
        statusKey: ticket.status,
        createdAt: nilSentry(ticket.createdAt, correctDateFormatter),
        resolvedAt: nilSentry(ticket.resolvedAt, correctDateFormatter) || '-',
        canceledAt: nilSentry(ticket.canceledAt, correctDateFormatter) || '-',
        payment: nilSentry(ticket.totals.payment.real.value, this.localiseAndRoundDecimals),
        winnings:
          nilSentry(ticket.totals.winnings.real.value, this.localiseAndRoundDecimals) || '-',
        paidoutAt: nilSentry(ticket.paidOutAt, correctDateFormatter) || '-',
        channel: this.getTicketChannelName(ticket),
        canceledTax:
          nilSentry(ticket.totals.canceledAmount.canceledTax, this.localiseAndRoundDecimals) || '-',
        paymentTax: nilSentry(ticket.totals.stake.paymentTax, this.localiseAndRoundDecimals) || '-',
        //        payoutTax: nilSentry(ticket.totals.taxes.find(({ type }) => type === 'PAYOUT')?.value,
        //          this.localiseAndRoundDecimals) || '-',
        payoutTax: nilSentry(ticket.totals.payout.payoutTax, this.localiseAndRoundDecimals) || '-',
        paymentBetshop:
          nilSentry(
            ticket.origin.find(({ type, source }) => type === 'BETSHOP' && source === 'PAYIN'),
            ({ name }) => name,
          ) || '-',
        paymentCashRegister:
          nilSentry(
            ticket.origin.find(
              ({ type, source }) => type === 'CASH_REGISTER' && source === 'PAYIN',
            ),
            ({ name }) => name,
          ) || '-',
        paymentDevice:
          nilSentry(
            ticket.origin.find(({ type, source }) => type === 'DEVICE' && source === 'PAYIN'),
            ({ name }) => name,
          ) || '-',
        paymentOperator:
          nilSentry(
            ticket.origin.find(({ type, source }) => type === 'OPERATOR' && source === 'PAYIN'),
            ({ name }) => name,
          ) || '-',
        payoutBetshop:
          nilSentry(
            ticket.origin.find(({ type, source }) => type === 'BETSHOP' && source === 'PAYOUT'),
            ({ name }) => name,
          ) || '-',
        payoutCashRegister:
          nilSentry(
            ticket.origin.find(
              ({ type, source }) => type === 'CASH_REGISTER' && source === 'PAYOUT',
            ),
            ({ name }) => name,
          ) || '-',
        payoutDevice:
          nilSentry(
            ticket.origin.find(({ type, source }) => type === 'DEVICE' && source === 'PAYOUT'),
            ({ name }) => name,
          ) || '-',
        payoutOperator:
          nilSentry(
            ticket.origin.find(({ type, source }) => type === 'OPERATOR' && source === 'PAYOUT'),
            ({ name }) => name,
          ) || '-',
        cancelOperator:
          nilSentry(
            ticket.origin.find(({ type, source }) => type === 'OPERATOR' && source === 'CANCEL'),
            ({ name }) => name,
          ) || '-',
        player: this.getPlayerEmail(ticket),
      };
    },
    getTicketChannelName(ticket) {
      return (
        ticket.client?.find(({ source, type }) => source === 'PAYIN' && type === 'CHANNEL')?.name
        || '-'
      );
    },
    async transformTickets(data) {
      const dateSecondsFormatter = await isoToDateTimeSeconds(this.locale);
      const dateFormatter = await isoToDateTime(this.locale);
      const timeFormatter = await isoToTimeSeconds(this.locale);

      const formatters = {
        dateFormatter,
        dateSecondsFormatter,
        timeFormatter,
      };
      const tickets = data?.map(partial(this.formatTicketForView, formatters));

      return tickets;
    },
    transformTotal(total) {
      return this.localiseAndRoundDecimals(total);
    },
    showTicketDetails(ticket) {
      this.setSelectedTicket(ticket);
      this.toggleDetailsModal(true);
      this.setDetailsModalConfig({
        title: `${this.getTranslation('ticketId')}: ${ticket.id}`,
        forCopy: ticket.id,
      });
    },
  },
  components: {
    TableFilterGrid,
  },
};
</script>
