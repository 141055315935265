import { defaultTo } from 'lodash';

export default {
  getTranslations(locale = 'en') {
    const translationModule = {
      en: () => import(/* webpackChunkName: "i18n.common" */ './en'),
      'sr-Latn': () => import(/* webpackChunkName: "i18n.common" */ './sr-Latn'),
      ro: () => import(/* webpackChunkName: "i18n.common" */ './ro'),
    };
    return defaultTo(translationModule[locale], translationModule.en)();
  },
};
