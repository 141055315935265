<template>
  <div>
    <v-menu
      v-if="!isMobile"
      v-model="menu"
      :close-on-content-click="false"
      nudge-top="20"
      offset-y
      top
    >
      <template v-slot:activator="{ on, attrs }">
        <v-list-item-avatar
          size="44"
          class="mx-auto menuWrapper"
          v-bind="attrs"
          v-on="on"
          :color="profileDetails.color"
        >
          <span class="white--text text-uppercase menuAvatar">
            {{ profileDetails.abbreviateTitle }}</span
          >
        </v-list-item-avatar>
      </template>
      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar size="44" :color="profileDetails.color">
              <span class="white--text text-uppercase menuAvatar">
                {{ profileDetails.abbreviateTitle }}</span
              >
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                {{ keycloak.tokenParsed.given_name }}
                {{ keycloak.tokenParsed.family_name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ keycloak.tokenParsed.email }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-icon>
              <v-btn @click="signOut()" class="text--text text-none" color="disabled" small>
                <LocalizedLabel>signOut</LocalizedLabel>
              </v-btn>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
        <v-divider />
        <v-switch @click="toggleTheme" v-model="$vuetify.theme.dark"
        :label="translations.toggleTheme" class="pl-4 pt-4 switchLabel" />
      </v-card>
    </v-menu>
    <v-dialog
      v-model="menu"
      transition="slide-x-reverse-transition"
      content-class="menuDialogWrapper"
      v-if="isMobile"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-avatar size="36" v-bind="attrs" v-on="on" :color="profileDetails.color">
          <span class="white--text text-uppercase menuAvatar subtitle-1">
            {{ profileDetails.abbreviateTitle }}</span
          >
        </v-avatar>
      </template>
      <v-card class="mx-auto" max-width="434" v-touch="{ right: () => swipe() }" tile>
        <v-img :aspect-ratio="16 / 9" :gradient="gradient">
          <v-layout class="pl-8 pb-4" column fill-height>
            <v-spacer></v-spacer>
            <v-avatar size="65" rounded :color="profileDetails.color" class="mb-2">
              <span class="white--text text-uppercase menuAvatar text-h5">
                {{ profileDetails.abbreviateTitle }}</span
              >
            </v-avatar>
            <div class="title font-weight-regular">
              {{ keycloak.tokenParsed.given_name }}
              {{ keycloak.tokenParsed.family_name }}
            </div>
            <div class="subtitle-1 font-weight-thin">
              {{ keycloak.tokenParsed.email }}
            </div>
          </v-layout>
        </v-img>
        <v-switch
          @click="toggleTheme"
          v-model="$vuetify.theme.dark"
          :label="translations.toggleTheme"
          class="pa-0 ma-0 pl-8 pt-8 switchLabel"
        >
        </v-switch>
        <v-card-actions>
          <v-btn class="text--text singoutButton text-none" text @click="signOut()">
            <LocalizedLabel>signOut</LocalizedLabel>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { idToColor, abbreviateTitle } from '@/utility';

export default {
  name: 'settingsMenu',
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    ...mapGetters(['keycloak', 'isMobile', 'translations']),
    profileDetails() {
      const info = this.keycloak.tokenParsed;
      return {
        color: idToColor(info.email, true),
        abbreviateTitle: abbreviateTitle(`${info.given_name} ${info.family_name}`),
      };
    },
    gradient() {
      if (this.$vuetify.theme.dark) {
        return 'to top right, #303030, #5f5a37';
      }
      return 'to top right, #fff, var(--v-primary-base)';
    },
  },
  methods: {
    ...mapActions(['signOut', 'setThemeValue']),
    swipe() {
      this.menu = false;
    },
    toggleTheme() {
      localStorage.setItem('isDarkTheme', this.$vuetify.theme.dark);
      this.setThemeValue(this.$vuetify.theme.dark ? 'dark' : 'light');
    },
  },
};
</script>

<style lang="scss" scoped>
.menuWrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 16px;
}

.menuAvatar {
  width: 100%;
  text-align: center;
}

.singoutButton {
  position: absolute;
  bottom: 16px;
  left: 16px;
}

.switchLabel label {
  padding-left: 15px;
}
</style>
