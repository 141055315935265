<template>
  <div>
    <v-app-bar height="60" fixed elevation="0" color="background">
      <v-toolbar-title class="title text--text px-0 font-weight-light">
        {{ 'Backoffice' }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <SettingsMenu />
    </v-app-bar>
  </div>
</template>

<script>
import SettingsMenu from '@/components/SettingsMenu';

export default {
  name: 'appBarMobile',
  components: {
    SettingsMenu,
  },
};
</script>
