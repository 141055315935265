const dark = {
  standalone: {
    primary: '#FFE843',
    secondary: '#424242',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',
    text: '#E0E0E0',
    subtitle: '#BCBCBC',
    background: '#1E1E1E',
    lost: '#cd6d6d',
    won: '#80c45a',
    disabled: '#8E8E8E',
    open: '#808b9c',
    accepted: '#808b9c',
    chips: '#4B4B4B',
    paidout: '#bbb04c',
    canceled: '#808B9C',
  },
  seven: {
    primary: '#009aff',
    secondary: '#424242',
    accent: '#38acf0',
    error: '#ff5252',
    info: '#2196f3',
    text: '#ffffff',
    success: '#4caf50',
    warning: '#fb8c00',
    darkGrey: '#2e3743',
    lightGrey: '#d3dadd',
    open: '#009aff',
    lost: '#cd6d6d',
    won: '#80c45a',
    jackpot: '#80c45a',
    closed: '#808b9c',
    paidout: '#bbb04c',
    expired: '#808b9c',
    pending: '#808b9c',
    rejected: '#cd6d6d',
  },
};

export default dark;
