import { tap } from 'lodash/fp';

// A pipe operator for async functions
export function composeAsync(...ms) {
  return ms.reduceRight((f, g) => (x) => g(x).then(f));
}

// Wraps the lodash' tap function to make it composable through an async pipe.
// Be careful with usage ('this' keyword)
export const asyncTap = (callback) => (x) => Promise.resolve(tap(callback)(x));
