export default {
  CLEAR_ALL_FILTERS: 'CLEAR_ALL_FILTERS',
  CLEAR_INITIAL_DATE_RANGE: 'CLEAR_INITIAL_DATE_RANGE',
  CLEAR_INITIAL_QUERY_PARAMS: 'CLEAR_INITIAL_QUERY_PARAMS',
  CLEAR_SELECTED_FILTERS_PER_TENANT: 'CLEAR_SELECTED_FILTERS_PER_TENANT',
  SET_ALL_FILTERS_PER_TENANT: 'SET_FILTERS_PER_TENANT',
  SET_AUTH: 'SET_AUTH',
  SET_CONFIG: 'SET_CONFIG',
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
  SET_CUSTOM_HEADERS: 'SET_CUSTOM_HEADERS',
  SET_DATE_RANGE: 'SET_DATE_RANGE',
  SET_DETAILS_MODAL_CONFIG: 'SET_DETAILS_MODAL_CONFIG',
  SET_DOWNLOAD_FILE: 'SET_DOWNLOAD_FILE',
  SET_HEADERS: 'SET_HEADERS',
  SET_INFO: 'SET_INFO',
  SET_INITIAL_DATE_RANGE: 'SET_INITIAL_DATE_RANGE',
  SET_INITIAL_QUERY_PARAMS: 'SET_INITIAL_QUERY_PARAMS',
  SET_LOADING: 'SET_LOADING',
  SET_MOBILE_DETECTION: 'SET_MOBILE_DETECTION',
  SET_SELECTED_FILTERS_PER_TENANT_VALUES: 'SET_SELECTED_FILTERS_PER_TENANT_VALUES',
  SET_SELECTED_TICKET: 'SET_SELECTED_TICKET',
  SET_SORTABLE_FIELDS: 'SET_SORTABLE_FIELDS',
  SET_THEME_VALUE: 'SET_THEME_VALUE',
  TOGGLE_DETAILS_MODAL: 'TOGGLE_DETAILS_MODAL',
  SET_QUERY_WITH_DATE: 'SET_QUERY_WITH_DATE',
};
