import Vue from 'vue';
import App from '@/App';
import router from '@/router';
import store from '@/store';
import Keycloak from 'keycloak-js';
import dark from '@/plugins/vuetify/themes/dark';
import light from '@/plugins/vuetify/themes/light';
import { ConfigurationService } from '@nsftx/games-config';
import { BusService } from '@nsftx/games-bus';
import { assign, defaultTo } from 'lodash';
import httpStatic from '@/api/httpStatic';
import LocalizedLabel from './components/LocalizedLabel';
import i18n from './i18n';
import vuetify from './plugins/vuetify/vuetify';

Vue.use(LocalizedLabel);

const queryParams = new URLSearchParams(window.location.search);
const platformName = defaultTo(queryParams.get('platform'), 'standalone');
const locale = defaultTo(queryParams.get('locale'), 'en');
const slaveId = queryParams.get('slaveId');

// Set themes based on platform
vuetify.framework.theme.themes.dark = dark[platformName];
vuetify.framework.theme.themes.light = light[platformName];
const isDarkTheme = localStorage.getItem('isDarkTheme');
// Seven integration will always be light theme
vuetify.framework.theme.dark = isDarkTheme === 'true' && platformName === 'standalone';

const getTranslations = async (localeString) => {
  let racers = {};
  if (store.getters.config.productName === 'VirtualDragRaces') {
    const defaultRacers = {
      racer_1: 'Clint Hines',
      racer_2: 'Boris Dean',
      racer_3: 'Gary Rios',
      racer_4: 'Clark Willis',
      racer_5: 'Lester Fisher',
      racer_6: 'Kenny Lee',
      racer_7: 'Jason Looks',
      racer_8: 'Jason Blind',
      racer_9: 'Milton Jones',
      racer_10: 'Kim Park',
      racer_11: 'David Stokes',
      racer_12: 'Jody Horton',
      racer_13: 'Eddie Holmes',
      racer_14: 'Archie Barton',
      racer_15: 'Gary Wells',
      racer_16: 'Roland Foster',
      racer_17: 'Casey Lowe',
      racer_18: 'Evan Walker',
      racer_19: 'Eddie Cook',
      racer_20: 'Alex Luna',
    };
    const url = `/ui/${store.getters.config.tenantId}.json`;
    const { data } = await httpStatic(url);
    racers = data?.vdr ? data?.vdr.racers : defaultRacers;
  }
  const { default: translations } = await i18n.getTranslations(localeString);

  return assign(translations, racers);
};

const setup = {};

setup.standalone = async () => {
  const config = {
    platformName,
    locale,
  };

  store.dispatch('setConfig', config);

  store.state.translations = await getTranslations(config.locale);

  const { keycloakOptions, keycloakConfig } = store.state;
  const keycloak = new Keycloak(keycloakConfig);

  keycloak.init(keycloakOptions).then((auth) => {
    store.state.keycloak = keycloak;
    store.dispatch('setAuth', { token: keycloak.token, refreshToken: keycloak.refreshToken });

    if (!auth) {
      window.location.reload();
    } else {
      new Vue({
        vuetify,
        router,
        store,
        render: (h) => h(App),
      }).$mount('#app');
      store.dispatch('keycloakRefreshToken');
    }
  });
};

setup.seven = async () => {
  const productName = slaveId.replace('Plugin', '');
  const channel = 'RETAIL';
  const applicationName = 'admin';

  const configService = new ConfigurationService({
    environment: process.env.VUE_APP_ENVIRONMENT,
    applicationName,
    channel,
    productName,
    clientAdapters: [
      {
        name: 'GravityGatewayAdapter',
        options: {
          slaveId,
        },
      },
    ],
  });
  Vue.prototype.$config = configService;

  const config = await configService.getConfiguration();

  store.dispatch('setConfig', config);
  store.dispatch('setAuth', { token: config.user.token, refreshToken: config.user.refreshToken });

  const translations = await getTranslations(config.locale);
  store.state.translations = translations;

  const busService = new BusService({
    environment: config.environment,
    platform: config.platform,
    platformName: config.platformName,
    productName: config.productName,
    productId: config.productId,
    tenantId: config.tenantId,
    locale: config.locale,
    applicationName: config.applicationName,
    clientMessageProtocol: config.messageProtocol,
    adapters: [],
    clientAdapters: [
      {
        name: 'GravityGatewayAdapter',
        options: {
          slaveId,
        },
      },
    ],
  });

  Vue.prototype.$busService = busService;

  busService.start();

  new Vue({
    vuetify,
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
};

setup[platformName]();
