export default {
  DATE_TIME_PICKER: 'DATE_TIME_PICKER',
  COMPANY_FILTER: 'COMPANY_FILTER',
  CHANNEL_FILTER: 'CHANNEL_FILTER',
  INSTANCE_FILTER: 'INSTANCE_FILTER',
  BETSHOP_FILTER: 'BETSHOP_FILTER',
  PLAYER_FILTER: 'PLAYER_FILTER',
  OPERATOR_FILTER: 'OPERATOR_FILTER',
  PRODUCT_FILTER: 'PRODUCT_FILTER',
  SINGLE_PRODUCT_FILTER: 'SINGLE_PRODUCT_FILTER',
  STATUS_FILTER: 'STATUS_FILTER',
  SEARCH_FILTER: 'SEARCH_FILTER',
};
