import { formatISO, startOfDay, endOfDay } from 'date-fns';
import filterTypes from '@/utility/types/filterTypes';
import { productTypes, sevenTabTypes } from '@/utility/types';

const now = new Date();

export default {
  noJackpotProducts: [
    productTypes.CrashCash,
    productTypes['cash-crash'],
    productTypes.VirtualDragRaces,
  ],
  auth: {},
  info: null,
  dateRange: {
    dateFrom: formatISO(startOfDay(now)),
    dateTo: formatISO(new Date(endOfDay(now))),
  },
  queryWithDate: true,
  currentPage: 'tickets',
  sortableFields: {
    tickets: [],
    events: [],
  },
  activeFilters: {
    tickets: {
      seven: [
        filterTypes.DATE_TIME_PICKER,
        filterTypes.BETSHOP_FILTER,
        filterTypes.CHANNEL_FILTER,
        filterTypes.OPERATOR_FILTER,
        filterTypes.PLAYER_FILTER,
        filterTypes.STATUS_FILTER,
        filterTypes.SEARCH_FILTER,
      ],
      standalone: [...Object.keys(filterTypes)],
    },
    events: {
      seven: [filterTypes.DATE_TIME_PICKER, filterTypes.INSTANCE_FILTER, filterTypes.SEARCH_FILTER],
      standalone: [
        filterTypes.DATE_TIME_PICKER,
        filterTypes.SEARCH_FILTER,
        filterTypes.SINGLE_PRODUCT_FILTER,
      ],
    },
  },
  headers: {
    tickets: [
      {
        text: 'ticketId',
        align: 'start',
        sortable: false,
        value: 'id',
        default: true,
        visible: true,
        permanent: true,
      },
      {
        text: 'createdAt',
        align: 'end',
        sortable: false,
        value: 'createdAt',
        default: true,
        visible: true,
      },
      {
        text: 'payment',
        align: 'end',
        sortable: false,
        value: 'payment',
        totalKey: 'payment',
        default: false,
        visible: true,
      },
      {
        text: 'paymentTax',
        align: 'end',
        sortable: false,
        value: 'paymentTax',
        totalKey: 'paymentTax',
        default: false,
        visible: false,
      },
      {
        text: 'stake',
        totalKey: 'stake',
        align: 'end',
        sortable: false,
        value: 'totals.stake.real.value',
        default: true,
        visible: true,
      },
      {
        text: 'winnings',
        totalKey: 'winnings',
        align: 'end',
        sortable: false,
        value: 'winnings',
        default: true,
        visible: true,
      },
      {
        text: 'payout',
        totalKey: 'payout',
        align: 'end',
        sortable: false,
        value: 'totals.payout.real.value',
        default: false,
        visible: true,
      },
      {
        text: 'payoutTax',
        totalKey: 'payoutTax',
        align: 'end',
        sortable: false,
        value: 'payoutTax',
        default: false,
        visible: false,
      },
      {
        text: 'resolvedAt',
        align: 'end',
        sortable: false,
        value: 'resolvedAt',
        default: true,
        visible: false,
      },
      {
        text: 'paidOutAt',
        align: 'end',
        sortable: false,
        value: 'paidoutAt',
        default: false,
        visible: false,
      },
      {
        text: 'canceledAmount',
        totalKey: 'canceledAmount',
        align: 'end',
        sortable: false,
        value: 'totals.canceledAmount.real.value',
        default: false,
        visible: false,
      },
      {
        text: 'canceledAt',
        align: 'end',
        sortable: false,
        value: 'canceledAt',
        default: false,
        visible: false,
      },
      {
        text: 'canceledTax',
        totalKey: 'canceledTax',
        align: 'end',
        sortable: false,
        value: 'canceledTax',
        default: false,
        visible: false,
      },
      {
        text: 'paymentBetshop',
        align: 'end',
        sortable: false,
        value: 'paymentBetshop',
        default: false,
        visible: false,
      },
      {
        text: 'payinCashRegister',
        align: 'end',
        sortable: false,
        value: 'paymentCashRegister',
        default: false,
        visible: false,
      },
      {
        text: 'payinDevice',
        align: 'end',
        sortable: false,
        value: 'paymentDevice',
        default: false,
        visible: false,
      },
      {
        text: 'payinOperator',
        align: 'end',
        sortable: false,
        value: 'paymentOperator',
        default: false,
        visible: false,
      },
      {
        text: 'player',
        align: 'end',
        sortable: false,
        value: 'player',
        default: false,
        visible: true,
      },
      {
        text: 'payoutBetshop',
        align: 'end',
        sortable: false,
        value: 'payoutBetshop',
        default: false,
        visible: false,
      },
      {
        text: 'payoutCashRegister',
        align: 'end',
        sortable: false,
        value: 'payoutCashRegister',
        default: false,
        visible: false,
      },
      {
        text: 'payoutDevice',
        align: 'end',
        sortable: false,
        value: 'payoutDevice',
        default: false,
        visible: false,
      },
      {
        text: 'payoutOperator',
        align: 'end',
        sortable: false,
        value: 'payoutOperator',
        default: false,
        visible: false,
      },
      {
        text: 'cancelOperator',
        align: 'end',
        sortable: false,
        value: 'cancelOperator',
        default: false,
        visible: false,
      },
      {
        // assumes channel of payin
        text: 'channel',
        align: 'end',
        sortable: false,
        value: 'channel',
        default: true,
        visible: true,
      },
      {
        text: 'status',
        align: 'end',
        sortable: false,
        value: 'status',
        default: true,
        visible: true,
      },
      {
        text: '',
        align: 'end',
        sortable: false,
        value: 'selectIcon',
        default: true,
        visible: true,
      },
    ],
    events: [],
    bet: [
      {
        text: 'round',
        align: 'start',
        sortable: false,
        value: 'round',
      },
      {
        text: 'type',
        align: 'start',
        sortable: false,
        value: 'type',
      },
      {
        text: 'pick',
        align: 'start',
        sortable: false,
        value: 'pick',
      },
      {
        text: 'odds',
        align: 'start',
        sortable: false,
        value: 'odds',
      },
      {
        text: 'stake',
        align: 'end',
        sortable: false,
        value: 'stake',
      },
      {
        text: 'payoutTax',
        align: 'end',
        sortable: false,
        value: 'payoutTax',
      },
      {
        text: 'paymentTax',
        align: 'end',
        sortable: false,
        value: 'paymentTax',
      },
      {
        text: 'winnings',
        align: 'end',
        sortable: false,
        value: 'winnings',
      },
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'status',
      },
    ],
  },
  selectedFiltersPerTenant: {
    tenants: [],
    betshops: [],
    instances: [],
    products: [],
    operators: [],
    players: [],
    statuses: [],
    channels: [],
    search: '',
  },
  allFiltersPerTenant: {
    tenants: [],
    betshops: [],
    products: [],
    instances: [],
    operators: [],
    players: [],
    statuses: [],
    channels: [],
    search: '',
  },
  initialQueryParams: {
    tenants: [],
    betshops: [],
    products: [],
    instances: [],
    operators: [],
    players: [],
    statuses: [],
    channels: [],
    search: '',
  },
  config: null,
  keycloak: null,
  downloadFile: {},
  translations: {},
  isMobile: false,
  selectedTicket: {},
  rowsCount: [10, 25, 50, 100],
  loading: false,
  navigation: [
    { title: 'tickets', link: '/tickets', icon: 'mdi-note-text', type: sevenTabTypes.tickets },
    { title: 'events', link: '/events', icon: 'mdi-calendar-text', type: sevenTabTypes.events },
  ],
  toggleDetailsModal: false,
  detailsModalConfig: {},
  keycloakConfig: {
    url: process.env.VUE_APP_KEYCLOAK_URL,
    realm: process.env.VUE_APP_KEYCLOAK_REALM,
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
  },
  keycloakOptions: {
    responseType: 'code',
    flow: 'standard',
    enablePkce: true,
    pkceMethod: 'S256',
    onLoad: 'login-required',
  },
};
