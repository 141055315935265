import Vue from 'vue';
import Router from 'vue-router';
import Tickets from '@/pages/Tickets';
import Events from '@/pages/Events';
import beforeEach from './navigationGuards';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/tickets',
      name: 'Tickets',
      component: Tickets,
    },
    {
      path: '/events',
      name: 'Events',
      component: Events,
    },
    {
      path: '*',
      redirect: () => '/tickets',
    },
  ],
});

beforeEach(router);

export default router;
