import Vue from 'vue';
import { each, has } from 'lodash';
import types from './mutationTypes';

export default {
  [types.SET_CONFIG](state, config) {
    state.config = config;
  },
  [types.SET_AUTH](state, auth) {
    state.auth = auth;
  },
  [types.SET_LOADING](state, payload) {
    state.loading = payload;
  },
  [types.SET_MOBILE_DETECTION](state, payload) {
    state.isMobile = payload;
  },
  [types.TOGGLE_DETAILS_MODAL](state, payload) {
    state.toggleDetailsModal = payload;
  },
  [types.SET_DETAILS_MODAL_CONFIG](state, payload) {
    state.detailsModalConfig = payload;
  },
  [types.CLEAR_INITIAL_QUERY_PARAMS](state) {
    each(state.initialQueryParams, (value, key) => {
      switch (key) {
        case 'search':
          Vue.set(state.initialQueryParams, key, '');
          break;
        case 'tenants':
          break;
        case 'products':
          if (state.config.platformName !== 'seven') Vue.set(state.initialQueryParams, key, []);
          break;
        default:
          Vue.set(state.initialQueryParams, key, []);
          break;
      }
    });
  },
  [types.SET_INITIAL_QUERY_PARAMS](state, payload) {
    each(payload, (value, key) => {
      if (has(state.initialQueryParams, key)) {
        Vue.set(state.initialQueryParams, key, value);
      }
    });
  },
  [types.SET_QUERY_WITH_DATE](state, payload) {
    state.queryWithDate = payload;
  },
  [types.SET_SELECTED_TICKET](state, payload) {
    state.selectedTicket = payload;
  },
  [types.SET_DOWNLOAD_FILE](state, payload) {
    state.downloadFile = payload;
  },
  [types.SET_DATE_RANGE](state, payload) {
    state.dateRange = payload;
  },
  [types.SET_CURRENT_PAGE](state, payload) {
    state.currentPage = payload;
  },
  [types.SET_INFO](state, payload) {
    state.info = payload;
  },
  [types.SET_ALL_FILTERS_PER_TENANT](state, payload) {
    state.allFiltersPerTenant = payload;
  },
  [types.SET_SORTABLE_FIELDS](state, payload) {
    state.sortableFields = { ...state.sortableFields, [state.currentPage]: payload || [] };
  },
  [types.SET_THEME_VALUE](state, payload) {
    state.theme = payload;
  },
  [types.SET_CUSTOM_HEADERS](state, { page, headers }) {
    Vue.set(state.headers, page, headers);
  },
  [types.SET_HEADERS](state, { page, headers }) {
    const transformedHeaders = state.headers[page].map((header) => ({
      ...header,
      visible: headers.find(({ value }) => value === header.value).visible,
    }));
    Vue.set(state.headers, page, transformedHeaders);
  },
  [types.CLEAR_ALL_FILTERS](state) {
    state.selectedFiltersPerTenant = {
      tenants: [],
      betshops: [],
      products: [],
      instances: [],
      operators: [],
      players: [],
      statuses: [],
      channels: [],
      search: '',
    };
  },
  [types.CLEAR_SELECTED_FILTERS_PER_TENANT](state) {
    each(state.selectedFiltersPerTenant, (value, key) => {
      switch (key) {
        case 'search':
          Vue.set(state.selectedFiltersPerTenant, key, '');
          break;
        case 'tenants':
          break;
        case 'products':
          if (state.config.platformName !== 'seven') Vue.set(state.selectedFiltersPerTenant, key, []);
          break;
        default:
          Vue.set(state.selectedFiltersPerTenant, key, []);
          break;
      }
    });
  },
  [types.SET_SELECTED_FILTERS_PER_TENANT_VALUES](state, payload) {
    each(payload, (value, key) => {
      if (has(state.selectedFiltersPerTenant, key)) {
        Vue.set(state.selectedFiltersPerTenant, key, value);
      }
    });
  },
};
