<template>
  <div class="alignSelectFilters">
    <v-autocomplete
      :items="list"
      :value="selected"
      outlined
      dense
      :label="getTranslation(labelKey)"
      allow-overflow
      hide-details
      :item-text="itemText"
      return-object
      :type="inputType"
      :multiple="multiple"
      v-touch="{ left: () => swipe() }"
      :disabled="disabled"
      @change="updateFilters($event)"
    >
      <template v-slot:item="{ item }">
        <slot name="autocomplete-item" :item="item" />
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { first } from 'lodash';

export default {
  name: 'filterSimpleSelect',
  props: {
    listName: String,
    labelKey: String,
    disabled: { type: Boolean, default: false },
    itemText: { type: String, default: 'name' },
    multiple: { type: Boolean, default: false },
  },
  data() {
    return {
      inputType: 'text',
    };
  },
  methods: {
    ...mapActions(['setFilters', 'setSelectedFiltersPerTenantValues']),
    updateFilters(value) {
      const valueToStore = {
        [this.listName]: [value],
      };

      this.setSelectedFiltersPerTenantValues(valueToStore);
    },
  },
  computed: {
    ...mapGetters(['getTranslation', 'isMobile', 'getSelectedFilterPerTenant']),
    selected() {
      return first(this.getSelectedFilterPerTenant(this.listName));
    },
    length() {
      return this.list.length;
    },
    list() {
      return this.$store.getters.getAllForFilterPerTenant(this.listName);
    },
  },
  mounted() {
    this.inputType = this.isMobile ? 'button' : 'text';
  },
};
</script>

<style lang="scss" scoped>
.customCheckbox {
  pointer-events: none !important;
}
</style>
