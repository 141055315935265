const statuses = {
  won: {
    translationKey: 'won',
    icon: 'mdi-check-circle-outline',
  },
  lost: {
    translationKey: 'lost',
    icon: 'mdi-close-circle-outline',
  },
  open: {
    translationKey: 'open',
    icon: 'mdi-clock-outline',
  },
  paidout: {
    translationKey: 'paidOut',
    icon: 'mdi-check-circle-outline',
  },
  accepted: {
    translationKey: 'accepted',
    icon: 'mdi-clock-outline',
  },
  canceled: {
    translationKey: 'canceled',
    icon: 'mdi-cancel',
  },
  cancelled: {
    translationKey: 'cancelled',
    icon: 'mdi-cancel',
  },
  invalid: {
    translationKey: 'invalid',
    icon: 'mdi-cancel',
  },
  expired: {
    translationKey: 'expired',
    icon: 'mdi-clock-remove-outline',
  },
};

export const getStatusTranslationKey = (status) => statuses[status.toLowerCase()].translationKey;
export const getStatusIcon = (status) => statuses[status.toLowerCase()].icon;
export const getStatusColor = (status) => status.split(' ').join('').toLowerCase();
