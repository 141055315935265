import httpTypes from '@/utility/types/httpTypes';
import http from './http';

export default {
  async [httpTypes.GET_DATA](payload) {
    const response = await http.get(`${payload.route}`, payload.config);

    return response.data;
  },
  async [httpTypes.POST_DATA](payload) {
    const response = await http.post(`${payload.route}`, payload.data, payload.config);
    return { data: response.data, headers: response.headers };
  },
};
