import axios from 'axios';
import store from '../store';

const http = axios.create({
  baseURL: process.env.VUE_APP_API_BASEURL,
  timeout: 25000,
});

http.interceptors.request.use((config) => {
  const { token } = store.getters.auth;
  const { tenantId, productName } = store.getters.config;
  const requestBody = {
    ...config,
    headers: {
      ...config.headers,
      authorization: `Bearer ${token}`,
      'X-Nsft-Games-Tenant-Id': tenantId,
      'X-Nsft-Games-Product-Name': productName,
    },
  };

  return requestBody;
});

export default http;
