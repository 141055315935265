<template>
  <div>
    <AppBarMobile v-if="isMobile" />

    <NavbarDesktop v-if="!isMobile" />
    <v-main :class="{ mainViewWrapper: isMobile }">
      <v-container fluid class="pa-4">
        <router-view />
      </v-container>
    </v-main>
    <NavbarMobile v-if="isMobile" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import NavbarDesktop from '@/components/NavbarDesktop';
import NavbarMobile from '@/components/NavbarMobile';
import AppBarMobile from '@/components/AppBarMobile';

export default {
  name: 'standalone',
  components: {
    NavbarDesktop,
    NavbarMobile,
    AppBarMobile,
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
};
</script>
<style scoped>
.mainViewWrapper {
  padding-bottom: 60px !important;
  padding-top: 60px !important;
}
</style>
