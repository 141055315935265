import { getNumberLocale } from './localeRegistry';

const localisedFormat = (localeParam) => {
  const numberLocale = getNumberLocale(localeParam);
  return new Intl.NumberFormat(
    numberLocale.name,
    numberLocale.options,
  );
};

export default function roundDecimals(value, localeParam) {
  return localisedFormat(localeParam).format(value);
}
