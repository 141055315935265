const languageLocales = {
  en: () => import('date-fns/locale/de/index'),
  'sr-Latn': () => import('date-fns/locale/sr-Latn/index'),
};

const numberLocales = {
  en: {
    name: 'en-US',
    options: { maximumFractionDigits: 2, minimumFractionDigits: 2 },
  },
  'sr-Latn': {
    name: 'sr',
    options: { maximumFractionDigits: 2, minimumFractionDigits: 2 },
  },
};

export const getLanguageLocale = (localeParam) => languageLocales[localeParam] || languageLocales.en;
export const getNumberLocale = (localeParam) => numberLocales[localeParam] || numberLocales.en;
