<template>
  <div ref="inputWrapper" class="selectWrapper">
    <v-autocomplete
      :multiple="multiple"
      outlined
      dense
      :items="list"
      :type="inputType"
      :value="selected"
      :item-text="itemText"
      :disabled="disabled"
      :item-key="itemKey"
      :no-data-text="getTranslation('noData')"
      :hide-details="hideDetails"
      :search-input.sync="searchInput"
      :label="getTranslation(labelKey)"
      :return-object="returnObject"
      @blur="onBlur"
      @change="onMultiSelectChange"
      v-touch="{ left: () => swipe() }"
    >
      <template v-slot:item="{ item, attrs, on }">
        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
          <v-list-item-action>
            <v-checkbox :ripple="false" :input-value="active" class="customCheckbox"></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            {{ getTranslation(getContent(item)) }}
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-slot:selection="{ item, index }">
        <v-chip small label color="primary" class="background--text" dark v-if="index === 0">
          {{ getTranslation(getItemText(item)) }}
        </v-chip>
        <span v-if="index === 1" class="grey--text caption">
          {{ itemsSurplus }}
        </span>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { pipe } from 'lodash/fp';

export default {
  name: 'filterMultiselect',
  props: {
    listName: String,
    labelKey: String,
    disabled: Boolean,
    hideDetails: { type: Boolean, default: true },
    returnObject: { type: Boolean, default: true },
    itemKey: { type: String, default: 'value' },
    itemText: { type: String, default: 'name' },
    // Which item property's value to show on the dropdown list
    contentProp: { type: String, default: '' },
    multiple: { type: Boolean, default: true },
  },
  data() {
    return {
      searchInput: '',
      inputType: 'text',
      selectedItems: [],
      previousSelection: new Set(),
    };
  },
  methods: {
    ...mapActions(['setSelectedFiltersPerTenantValues']),
    getContent(item) {
      const text = this.contentProp ? item[this.contentProp] : item;
      return this.contentTransformator(text);
    },
    getItemText(item) {
      if (typeof item === 'object') {
        return item[this.itemText];
      }
      return this.contentTransformator(item);
    },
    onBlur() {
      const valuesSet = new Set(this.selectedItems.map(({ value }) => value));

      if (this.hasSelectionChanged(valuesSet)) {
        this.updateAndEmitFilters(this.selectedItems);
      }

      this.previousSelection = valuesSet;
    },
    onMultiSelectChange(newSelection) {
      this.selectedItems = newSelection;
      this.updateFilters(newSelection);
    },
    hasSelectionChanged(newSelection) {
      let selectionChanged = false;
      if (this.previousSelection.size !== newSelection.size) selectionChanged = true;

      this.previousSelection.forEach((item) => {
        if (!newSelection.has(item)) selectionChanged = true;
      });

      return selectionChanged;
    },
    updateFilters(value) {
      const valueToStore = {
        [this.listName]: value,
      };

      this.setSelectedFiltersPerTenantValues(valueToStore);
    },
    emitFilters(value) {
      this.$emit(`update-${this.listName}`, value);
    },
    updateAndEmitFilters(value) {
      const valueToStore = {
        [this.listName]: value,
      };

      this.setSelectedFiltersPerTenantValues(valueToStore);
      this.emitFilters(`update-${this.listName}`, value);
    },
  },
  computed: {
    shouldLowerCase() {
      return ['channels', 'statuses'].includes(this.listName);
    },
    shouldCapitalizeFirstLeter() {
      return ['channels'].includes(this.listName);
    },
    contentTransformator() {
      const transformators = [];
      if (this.shouldLowerCase) transformators.push((str) => str.toLowerCase());

      if (this.shouldCapitalizeFirstLeter) {
        transformators.push((str) => `${str.charAt(0).toUpperCase()}${str.slice(1)}`);
      }

      return pipe(...transformators);
    },
    ...mapGetters(['getTranslation', 'getSelectedFilterPerTenant']),
    selected() {
      return this.getSelectedFilterPerTenant(this.listName);
    },
    itemsSurplus() {
      return `(+${this.selected.length - 1})`;
    },
    length() {
      return this.list.length;
    },
    list() {
      return this.$store.getters.getAllForFilterPerTenant(this.listName);
    },
  },
};
</script>

<style lang="scss" scoped>
.selectWrapper {
  width: 100%;
  .v-autocomplete.v-select.v-input--is-focused input {
    min-width: 0px;
  }

  ::v-deep {
    .v-select__selections {
      flex-wrap: nowrap !important;
      max-height: 40px;
    }
  }

  .v-autocomplete.v-select.v-select__selections {
    white-space: nowrap;
  }

  .v-select_slot {
    max-width: none;
  }
}

.customCheckbox {
  pointer-events: none !important;
}
</style>
