<template>
  <div>
    <!-- FILTERS -->
    <div class="filterHandlerWrapper" :class="{ mobile: isMobile }">
      <FilterHandler
        v-if="!isMobileAndStandalone"
        ref="filterHandler"
        :listName="listName"
        @setFilters="handleSetFilters"
        @filtersReady="onFiltersReady"
      />
      <FilterDialog
        ref="filterDialog"
        v-else
        @setFilters="handleSetFilters"
        @filtersReady="onFiltersReady"
      />
      <div
        class="filterButtonWrapper"
        :style="{ 'justify-content': showExportButton ? 'start' : 'end' }"
      >
        <v-select
          dense
          outlined
          hide-details
          v-if="showExportButton"
          v-model="exportFormat"
          class="export-dropown"
          :items="exportOptions"
          :label="getTranslation('exportDropdownLabel')"
        />

        <!-- EXPORT -->
        <v-btn
          @click="handleExportAction"
          v-if="showExportButton"
          icon
          height="40"
          width="40"
          :disabled="loading"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-export-variant</v-icon>
            </template>
            <span>{{ getTranslation('export') }}</span>
          </v-tooltip>
        </v-btn>

        <!-- RELOAD -->
        <v-btn @click="reload()" icon height="40" width="40" :disabled="loading">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-reload</v-icon>
            </template>
            <span>{{ getTranslation('reload') }}</span>
          </v-tooltip>
        </v-btn>

        <!-- RESET FILTERS-->
        <v-btn @click="handleResetFilters" icon height="40" width="40">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-filter</v-icon>
            </template>
            <span>{{ getTranslation('resetFilters') }}</span>
          </v-tooltip>
        </v-btn>
      </div>
    </div>
    <v-row>
      <v-col>
        <v-card outlined>
          <!-- TABLE -->
          <!-- COLUMN FILTER -->
          <TableColumnsSelect :headers="columnsData" v-if="showDropdownMenu" />
          <v-data-table
            :class="[
              'elevation-0',
              'multiColumnsTable',
              { 'seven-table-style': isSeven },
              { 'row-pointer': shouldShowPointerOnRow },
            ]"
            :headers="visibleHeaders"
            :page="pagination.page"
            :options.sync="options"
            :items-per-page.sync="pagination.size"
            :server-items-length="pagination.total"
            :no-data-text="getTranslation('noData')"
            :loading-text="getTranslation('loading')"
            :footer-props="combineFooterProps"
            :items="items"
            :loading="loading"
            @update:options="onOptionsUpdate($event)"
            @click:row="onClickOnRow"
          >
            <!-- PROGRESS BAR -->
            <template v-slot:progress :style="{ padding: '0px' }">
              <v-progress-linear indeterminate color="yellow w-full darken-2" />
            </template>
            <!-- IS PROMO RAIN AS CHIP  -->
            <template v-slot:[`item.isPromoRain`]="{ item }">
              <v-chip
                class="isPromoRain"
                :color="getPromoRainColor(item.isPromoRain)"
                small
                outlined
                label
                >{{ getTranslation(item.isPromoRain) }}</v-chip
              >
            </template>
            <!-- STATUS AS CHIP  -->
            <template v-slot:[`item.status`]="{ item }">
              <v-chip class="status" :color="getColor(item.statusKey)" small outlined label>{{
                item.status
              }}</v-chip>
            </template>
            <!-- COLUMN SELECT BUTTON -->
            <template slot="header.selectIcon">
              <v-btn icon @click="toggleDropdownMenu()" class="disabled--text visibleOnOverlay">
                <v-icon :color="selectHeadersColor">mdi-filter-variant</v-icon>
              </v-btn>
            </template>
            <!-- TOTALS ROW -->
            <template slot="body.prepend" v-if="shouldShowTotalsMobile">
              <table class="totalsRowMobile">
                <tr>
                  <td>
                    <LocalizedLabel>total</LocalizedLabel>
                  </td>
                </tr>
                <tr v-for="(total, index) in transformedTotals" :key="index" v-show="total.value">
                  <td>{{ total.text }}</td>
                  <td class="formatValue">{{ total.value }}</td>
                </tr>
              </table>
              <v-divider></v-divider>
            </template>
            <!-- TOTALS ROW DESKTOP -->
            <template slot="body.append" v-if="shouldShowTotals">
              <tr class="totalsRow">
                <td>
                  <LocalizedLabel>total</LocalizedLabel>
                </td>
                <td v-for="(total, index) in transformedTotals" :key="index">
                  <div v-if="!loading">{{ total.value }}</div>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-overlay :value="showDropdownMenu" @click.native="showDropdownMenu = false" opacity="0">
          </v-overlay>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  update,
  toNumber,
  assign,
  set,
  clone,
  drop,
  isNull,
  isEmpty,
  merge,
  head,
  isArray,
  defaultTo,
  throttle,
  concat,
} from 'lodash';
import { pipe, tap } from 'lodash/fp';
import {
  getStatusColor,
  formatQueryParams,
  composeAsync,
  asyncTap,
  prepareForQueryParamsReplace,
  getColumnsData,
  productRegistry,
  setLSData,
  eventBus,
} from '@/utility';

import TableColumnsSelect from '@/components/TableColumnsSelect';
import FilterHandler from '@/components/Filters/FilterHandler';
import FilterDialog from '@/components/Filters/FilterDialog';
import { exportFormatTypes, eventBusMessageTypes } from '@/utility/types';

export default {
  name: 'tableFilterGrid',
  components: {
    TableColumnsSelect,
    FilterHandler,
    FilterDialog,
  },
  props: {
    customUrl: {
      type: String,
      default: '',
    },
    // The tickets view has pre-defined table headers
    // The event payload is individiual for each product
    // So we need to infer the headers
    inferTableHeaders: {
      type: Boolean,
      default: false,
    },
    listName: {
      type: String,
      default: '',
    },
    /**
     * Run immediatelly after fetching data
     *
     * Needed as the vuetify components require a model different from
     * the one we receive
     */
    transformList: {
      type: Function,
      default: () => {},
    },
    /**
     * Maps all the totals to a preffered format
     * (e.g. numbers of decimals)
     */
    transformTotal: {
      type: Function,
      default: () => {},
    },
    onClickOnRow: {
      type: Function,
      default: () => {},
    },
    headers: {
      type: Array,
    },
    additionalHeaders: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      exportFormat: { value: 'JSON', text: 'JSON' },
      totals: {},
      filtersReady: false,
      initialQueryMade: false,
      pagination: {
        // * Must initialize server-items-length with 0,
        // * Otherwise, the initial options.page property
        // * Will be overriden to 1 by VData
        // * (see updateOptions method)
        // * https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/components/VData/VData.ts
        total: 0,
      },
      options: {
        itemsPerPage: toNumber(this.$route.query.itemsPerPage) || 10,
        page: toNumber(this.$route.query.page) || 1,
        sortBy: [
          isArray(this.$route.query.sortBy)
            ? head(this.$route.query.sortBy)
            : this.$route.query.sortBy,
        ],
        sortDesc: [this.$route.query.sortDesc === true],
      },
      items: [],
      showDropdownMenu: false,
    };
  },
  computed: {
    ...mapGetters([
      'allFilters',
      'config',
      'currentPage',
      'currentPageHeaders',
      'dateRange',
      'footerProps',
      'getTranslation',
      'hasFiltersReqFailed',
      'isMobile',
      'isMobileAndStandalone',
      'getSelectedFilterPerTenant',
      'queryWithDate',
      'isSeven',
      'loading',
      'localStoragePath',
      'productName',
    ]),
    endpointUrl() {
      return this.customUrl ? this.customUrl : `/${this.listName}`;
    },
    shouldShowPointerOnRow() {
      return this.listName === 'tickets';
    },
    product() {
      return productRegistry.getProduct(this.productName) || {};
    },
    showExportButton() {
      return (
        (!this.isMobileAndStandalone && this.product.name !== 'vdr') || this.listName === 'tickets'
      );
    },
    selectHeadersColor() {
      return this.isSeven ? 'white' : '';
    },
    shouldShowTotalsMobile() {
      return this.items?.length && this.isMobileAndStandalone && this.totals;
    },
    shouldShowTotals() {
      return this.items?.length && !this.isMobileAndStandalone && this.totals;
    },
    exportOptions() {
      return Object.keys(exportFormatTypes).map((key) => ({
        text: key,
        value: key,
      }));
    },
    pageCount() {
      return this.getPageCount(this.pagination);
    },
    combineFooterProps() {
      return assign({ disablePagination: this.loading }, this.footerProps);
    },
    isFirstPage() {
      return this.options.page === 1;
    },
    visibleHeaders() {
      const translatedHeaders = this.translateHeaders(this.headers).filter(
        ({ visible }) => visible,
      );
      return translatedHeaders;
    },
    columnsData() {
      return this.headers;
    },
    /**
     * The non existing totals have the total.value property equal to null,
     * This function makes sure no transformations are done to those totals.
     */
    transformedTotals() {
      return this.getTotals().map((total) => {
        const value = isNull(total.value) ? null : this.transformTotal(total.value);
        return { ...total, value };
      });
    },
  },
  methods: {
    ...mapActions([
      'getData',
      'resetFilters',
      'setDownloadFile',
      'postData',
      'setSortableFields',
      'setSort',
      'setHeaders',
      'setCustomHeaders',
    ]),
    handleResetFilters() {
      this.resetSortBy();
      this.resetFilters();
      this.handleSetFilters();
    },
    resetSortBy() {
      this.options.sortBy = [];
    },
    getColumnsForExport() {
      return `columnsForExport=${getColumnsData(this.localStoragePath)
        .filter(({ visible }) => visible)
        .map(({ text }) => text.replace('payin', 'payment'))
        .filter((text) => text)
        .join(',')}`;
    },
    getCombinedFilters() {
      const excessPropsFromVuetify = ['groupBy', 'groupDesc', 'multiSort', 'mustSort'];
      const cleanOptions = Object.entries(this.options)
        .filter(([key]) => !excessPropsFromVuetify.includes(key))
        .reduce((acc, [key, value]) => {
          // Array.reduce is internally pure, so no need to worry
          // about inducing side effects outside of scope
          /* eslint-disable no-param-reassign */
          acc[key] = value;
          return acc;
        }, {});
      return merge(cleanOptions, this.allFilters);
    },
    async handleExportAction() {
      const response = await this.getExportedData();
      this.extractAndStoreFileData(response);
    },
    extractAndStoreFileData(response) {
      const fileName = response.headers['content-disposition'].split('=')[1];
      const href = window.URL.createObjectURL(response.data);

      this.setDownloadFile({
        href,
        fileName,
        download: true,
      });
    },
    getExportedData() {
      return this.postData({
        route: `${this.listName}/action/export?${formatQueryParams({
          dateFrom: this.dateRange.dateFrom,
          dateTo: this.dateRange.dateTo,
          locale: this.config.locale,
          timezone: true,
          ...this.getCombinedFilters(),
        })}&${this.getColumnsForExport()}`,
        data: {},
        config: {
          responseType: 'blob',
          timeout: 13000,
          headers: {
            'Content-Type': exportFormatTypes[this.exportFormat],
          },
        },
        loading: true,
      });
    },
    onFiltersReady() {
      this.markFiltersAsReady();
      if (!this.initialQueryMade) {
        this.handleOptionsAndFiltersChange(this.getCombinedFilters());
      }
    },
    markFiltersAsReady() {
      this.filtersReady = true;
    },
    async onOptionsUpdate(options) {
      set(this, 'options', options);
      // This check exists because the update:options is triggered immediatelly as the app starts,
      // (possible vuetify behaviour), i.e. way before we've fetched all the available filters
      // and selected local ones from query params, which is unconvenient. By checking for products,
      // we're making sure that we've initialised the filters, as a tenant cannot exist without
      //  at least one active product
      if (!this.filtersReady) return;

      set(this, 'initialQueryMade', true);
      this.handleOptionsAndFiltersChange(this.getCombinedFilters());
    },
    initialQueryTest() {
      if (this.initialQueryMade) return;

      eventBus.$emit(eventBusMessageTypes.INITIAL_QUERY_MADE);
      set(this, 'initialQueryMade', true);
    },
    async handleSetFilters() {
      if (this.isFirstPage) {
        // If on the first page, no need to change the pagination
        this.handleOptionsAndFiltersChange(this.getCombinedFilters());
      } else {
        // If not on the first page, reset page to 1, the update handler will do the rest
        set(this.pagination, 'total', 0);
        set(this.options, 'page', 1);
      }
    },
    async triggerGetAndStoreInitialFilters() {
      await this.$refs.filterHandler.getAndStoreInitialFilters();
      if (this.isMobile) {
        await this.$refs.filterDialog.getAndStoreInitialFilters();
      }
    },
    async reload() {
      if (this.hasFiltersReqFailed) {
        this.triggerGetAndStoreInitialFilters();
      }

      this.handleOptionsAndFiltersChange(this.getCombinedFilters());
    },
    refreshQueryParams(newParams) {
      this.$router.replace(
        {
          query: { ...prepareForQueryParamsReplace(newParams), locale: this.config.locale },
        },
        () => {},
      );
    },
    /**
     * Transforms the response to a format
     * that fits Vuetify's internal data structures
     */
    switchPaginationIndexing(response) {
      const { pagination, ...other } = response;

      return Promise.resolve({ pagination, ...other });
    },
    getHeadersWithAdditionalHeaders(currentPageHeaders) {
      const columnSelector = currentPageHeaders.find(({ value }) => value === 'selectIcon');

      const headers = columnSelector
        ? concat(
          currentPageHeaders.slice(0, 2),
          this.additionalHeaders,
          currentPageHeaders.slice(2, -1),
          [columnSelector],
        )
        : concat(currentPageHeaders, this.additionalHeaders);

      return headers;
    },
    async query(params) {
      return this.getData({
        route: `${this.endpointUrl}?${params}`,
        loading: true,
      });
    },
    getAreHeadersEmpty(headers) {
      return headers.length === 0 || (headers.length === 1 && headers[0].value === 'selectIcon');
    },
    /**
     * Only look for the (possibly) existing totals to the currently visible headers
     */
    getTotals() {
      return drop(
        this.visibleHeaders.map((header) => {
          const { totalKey } = header;
          const totalExistsForHeader = isEmpty(this.totals) ? false : totalKey in this.totals;
          const total = totalExistsForHeader ? defaultTo(this.totals[totalKey], 0) : null;
          return {
            text: header.text,
            value: total,
          };
        }),
      );
    },
    /**
     * @param {Promise<{data: Object, pagination:Object}>} result
     * Updates the list view.
     */
    async updateView(result) {
      set(this, 'items', result.data[this.listName]);
      if (result.pagination) set(this, 'pagination', result.pagination);
    },
    compareLocalHeadersWithPersistedHeaders(localHeaders) {
      if (this.getAreHeadersEmpty(localHeaders)) return;

      const persistedData = getColumnsData(this.localStoragePath);

      // If no persisted data is found, set everything as was inferred
      if (!persistedData || !persistedData.length) {
        setLSData(this.localStoragePath, localHeaders);
      } else {
        const isMatchingHeaderWith = (headerOne) => (headerTwo) => headerOne.value === headerTwo.value;
        // Everything that is found in the local headers, but not in the persisted headers
        // should be persisted. On the other hand, anything found in the persisted headers
        // and not found in the local state needs to be removed from persistance. So, it is sufficient to always save the contents
        // of the local headers with the visible property of each header set to whatever the value is for that header.visible
        // in the persisted headers, or true if not found in the persisted headers.
        const newHeadersData = localHeaders.map((localHeader) => {
          const persistedHeader = persistedData.find(isMatchingHeaderWith(localHeader));
          const newVisibility = isEmpty(persistedHeader)
            ? localHeader.visible
            : persistedHeader.visible;

          return { ...localHeader, visible: newVisibility };
        });

        setLSData(this.localStoragePath, newHeadersData);
        this.setHeaders({ page: this.currentPage, headers: newHeadersData });
      }
    },
    /**
     * Combines query params, fetches and transforms the data
     * to a view appropriate format
     *
     * Perform a couple of side effects, such as
     * updating the url bar with the current query params
     * and saving the totals in the component
     */
    handleOptionsAndFiltersChange(options) {
      const throttled = throttle(
        pipe(
          this.getQueryParamsObject,
          tap(this.refreshQueryParams),
          formatQueryParams,
          composeAsync(
            this.query,
            asyncTap(this.initialQueryTest),
            asyncTap(({ ticketTotals }) => {
              this.totals = ticketTotals;
            }),
            this.switchPaginationIndexing,
            this.getResponseWithValidPagination,
            asyncTap((response) => {
              this.setInferredTableHeaders(response);
              this.compareLocalHeadersWithPersistedHeaders(this.currentPageHeaders);
            }),
            this.getTransformedListResponse,
            asyncTap(({ sortableFields }) => {
              this.setSortableFields(sortableFields || []);
            }),
            this.updateView,
          ),
        ),
        500,
      );

      return throttled(options);
    },
    translateHeaders(headers) {
      return headers.map((header) => ({ ...header, text: this.getTranslation(header.text) }));
    },
    toggleDropdownMenu() {
      this.showDropdownMenu = !this.showDropdownMenu;
    },
    getPromoRainColor(value) {
      // We already have colors for won and lost
      // We're just reusing them here
      return value ? 'won' : 'lost';
    },
    getColor(status) {
      return getStatusColor(status);
    },
    getPageCount(pagination) {
      return Math.ceil(pagination?.total / pagination?.size) || 1;
    },
    getQueryParamsObject(options) {
      const params = {
        listName: this.listName,
        dateFrom: this.dateRange.dateFrom,
        dateTo: this.dateRange.dateTo,
        queryWithDate: this.queryWithDate,
      };

      return assign(clone(options), params);
    },
    /**
     * Returns the response with transformed list
     */
    async getTransformedListResponse(response) {
      const list = await this.transformList(response.data);
      const path = `data.${this.listName}`;

      return assign(update(clone(response), path, () => list));
    },
    constructHeader(key) {
      const baseObj = {
        text: '',
        align: 'start',
        sortable: false,
        value: '',
        default: true,
        visible: true,
      };

      return { ...baseObj, text: key, value: key };
    },
    setInferredTableHeaders(response) {
      if (!this.inferTableHeaders) return;

      let body;

      if (Array.isArray(response.data) && response.data.length > 0) {
        [body] = response.data;
      } else {
        body = {};
      }

      const headers = Object.keys(body)
        .filter((key) => Object.hasOwnProperty.call(body, key))
        .map((key) => this.constructHeader(key));

      // Find the header with value 'tournamentId'
      const tournamentIdHeader = headers.find((header) => header.value === 'tournamentId');
      const otherHeaders = headers.filter((header) => header.value !== 'tournamentId');

      let reorderedHeaders = [];

      // If tournamentIdHeader is found, place it first
      if (tournamentIdHeader) {
        reorderedHeaders.push(tournamentIdHeader);
      }

      reorderedHeaders = reorderedHeaders.concat(otherHeaders);

      // Append the additional header at the end
      reorderedHeaders.push({
        text: '',
        align: 'end',
        sortable: false,
        value: 'selectIcon',
        default: true,
        visible: true,
      });

      if (this.getAreHeadersEmpty(reorderedHeaders)) return;
      this.setCustomHeaders({
        page: this.listName,
        headers: this.getHeadersWithAdditionalHeaders(reorderedHeaders),
      });
    },
    /**
     * Removes pagination inconsistencies
     */
    getResponseWithValidPagination(response) {
      const { pagination } = response;
      const pageCount = this.getPageCount(pagination);
      // Reset to max page
      if (pageCount < pagination?.page) {
        pagination.page = pageCount;
      }

      return Promise.resolve(assign(response, { pagination }));
    },
  },
};
</script>

<style lang="scss" scoped>
.export-dropdown {
  width: fit-content;
}
.seven-table-style {
  ::v-deep {
    .v-data-table-header {
      th {
        color: white !important;
      }
      background-color: var(--v-primary-base);
    }
    .v-data-table-header__icon {
      color: white !important;
    }
  }
}

::v-deep {
  .v-data-table__progress {
    .column {
      padding: 0px;
    }
  }
  .row-pointer {
    tbody {
      tr {
        cursor: pointer;
      }
    }
  }
  .v-pagination__navigation,
  .v-pagination__item {
    box-shadow: none;
  }

  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
    > .v-input__control
    > .v-input__slot {
    box-shadow: none;
  }
}

.max-width-80 {
  max-width: 80px;
}
</style>
