import { find } from 'lodash';

export default {
  products: {
    greyhounds: {
      name: 'greyhounds',
      fqn: 'GreyhoundRaces',
      aliases: ['GreyhoundRaces', 'GR', 'greyhoundraces', 'greyhoundRaces'],
    },
    kolor: {
      name: 'kolor',
      fqn: 'VirtualKolor',
      aliases: ['Kolor', 'NVNVKolor'],
    },
    lightningKolor: {
      name: 'lightningKolor',
      fqn: 'LightningKolor',
      aliases: ['NVNVLightningKolor'],
    },
    lightningRoulette: {
      name: 'lightningRoulette',
      fqn: 'LightningRoulette',
      aliases: ['NVNVLightningRoulette'],
    },
    lightningLuckysix: {
      name: 'lightningLuckysix',
      fqn: 'NVNVLightningLuckySix',
      aliases: ['NVNVLightningLuckySix'],
    },
    luckysix: {
      name: 'luckysix',
      fqn: 'LuckySix',
      aliases: ['LuckySix', 'LS', 'luckySix'],
    },
    luckyx: {
      name: 'luckyx',
      fqn: 'LuckyX',
      aliases: ['LuckyX', 'LX', 'luckyX'],
    },
    nextsix: {
      name: 'nextsix',
      fqn: 'NextSix',
      aliases: ['NextSix', 'NS', 'nextSix'],
    },
    numbersBetting: {
      name: 'numbersBetting',
      fqn: 'NumbersBetting',
      aliases: ['NumbersBetting'],
    },
    roulette: {
      name: 'roulette',
      fqn: 'VirtualRoulette',
      aliases: ['Roulette'],
    },
    slotCars: {
      name: 'slotCars',
      fqn: 'SlotCarRaces',
      aliases: ['SlotCarRaces', 'SCR', 'slotcarraces', 'slotCarRaces'],
    },
    vdr: {
      name: 'vdr',
      fqn: 'VirtualDragRaces',
      aliases: ['VirtualDragRaces', 'VDR', 'virtualdragraces', 'virtualDragRaces'],
    },
    vgr: {
      name: 'vgr',
      fqn: 'VirtualGreyhoundRaces',
      aliases: ['VirtualGreyhoundRaces', 'VGR', 'virtualgreyhoundraces', 'virtualGreyhoundRaces'],
    },
    vhr: {
      name: 'vhr',
      fqn: 'VirtualHorseRaces',
      aliases: ['VirtualHorseRaces', 'VHR', 'virtualhorseraces', 'virtualHorseRaces'],
    },
    vms: {
      name: 'vms',
      fqn: 'VirtualMotorcycleSpeedway',
      aliases: [
        'VirtualMotorcycleSpeedway',
        'VMS',
        'virutalmotorcyclespeedway',
        'virtualMotorcycleSpeedway',
      ],
    },
    vps: {
      name: 'vps',
      fqn: 'VirtualPenaltyShootout',
      aliases: [
        'VirtualPenaltyShootout',
        'VPS',
        'virtualpenaltyshootout',
        'virtualPenaltyShootout',
      ],
    },
    vsoccer: {
      name: 'vsoccer',
      fqn: 'VirtualSoccer',
      aliases: ['VirtualSoccer', 'VirtualSoccerDev', 'virtualsoccer', 'virtualSoccer'],
    },
    keno: {
      name: 'keno',
      fqn: 'Keno',
      aliases: ['Keno'],
    },
    crashcash: {
      name: 'crashcash',
      fqn: 'CrashCash',
      aliases: ['CrashCash', 'CC', 'Crash', 'GamesTest'],
    },
  },
  getProduct(productName) {
    const productByName = this.products[productName];

    if (productByName) {
      return productByName;
    }

    const productByAlias = find(this.products, (product) => { // eslint-disable-line
      if (product.aliases && product.aliases.indexOf(productName) > -1) {
        return product;
      }
    });

    return productByAlias;
  },
};
