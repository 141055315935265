<template>
  <div>
    <v-container fluid class="pa-4 ma-0">
      <div class="d-flex flex-no-wrap">
        <div v-if="detailsModalConfig.title" class="title oneliner">
          {{ detailsModalConfig.title }}
        </div>
        <v-btn
          v-if="detailsModalConfig.forCopy"
          icon
          x-small
          class="pa-0 copyButton"
          @click="copy(detailsModalConfig.forCopy)"
        >
          <v-icon color="subtitle">mdi-content-copy</v-icon></v-btn
        >
        <v-spacer />
        <v-row align="center" justify="end">
          <v-btn icon v-if="detailsModalConfig" @click="toggleDetailsModal(false)">
            <v-icon color="subtitle">mdi-close</v-icon>
          </v-btn>
        </v-row>
      </div>
      <TicketDetails />
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import TicketDetails from '@/components/TicketDetails';

export default {
  name: 'DetailsModal',
  components: {
    TicketDetails,
  },
  computed: {
    ...mapGetters(['detailsModalConfig']),
  },
  methods: {
    ...mapActions(['toggleDetailsModal']),
    copy(item) {
      navigator.clipboard.writeText(item);
    },
  },
};
</script>

<style lang="scss" scoped>
.copyButton {
  margin: 6px 16px 6px 8px;
}

@media screen and (max-width: 1024px) {
  .oneliner {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
